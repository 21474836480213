import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  // // ----------redux store useDispatch & useSelector --------------------
  // const dispatch = useDispatch()
  const state = useSelector((state) => state);
  let user = state.common.user;

  useEffect(() => {
    //if user logged, redirect to specific page depending on role
    if (user.username) {
      if (user.roles.indexOf("maintenance") > -1) {
        navigate("/machines");
        return;
      }
      if (user.roles.indexOf("engineer") > -1) {
        navigate("/field");
        return;
      }
      if (user.roles.indexOf("operator") > -1) {
        navigate("/profile");
        return;
      }
      if (user.roles.indexOf("surveyor") > -1) {
        navigate("/field");
        return;
      }
      if (user.roles.indexOf("supervisor") > -1) {
        navigate("/shift");
        return;
      }
      if (user.roles.indexOf("director") > -1) {
        navigate("/field");
        return;
      }
    } else {
      navigate("/login");
    }
  }, [user, navigate]);
  return <div></div>;
}

export default Home;
