import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
//import Components
import PatternCard from "components/board/patterncard";
//import actions
import { fetchPatternByFilter } from "redux/fieldRedux/fieldActions";

function PatternList(props) {
  const { t } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  let patterns = state.field.patterns;
  let patternFilter = state.field.filters;

  React.useEffect(() => {
    dispatch(
      fetchPatternByFilter({
        pits: patternFilter.pits,
        states: [1],
        stages: ["all"],
      })
    );
    // eslint-disable-next-line
  }, [patternFilter]);

  if (patterns.length === 0) {
    return (
      <Typography>{t("components.patternlist.text_no_pattern")}</Typography>
    );
  }

  return (
    <Grid item xs={12}>
      {patterns &&
        patterns[0] &&
        patterns.map((x, i) => {
          return <PatternCard pattern={x} key={i} />;
        })}
    </Grid>
  );
}

export default PatternList;
