import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, IconButton, Switch, Typography } from "@mui/material";
import { Add, Create } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

//import components
//import actions
import { modalConfirm } from "redux/commonRedux/commonActions";
import {
  createUpdateUserAction,
  retreiveUserListAction,
} from "redux/userRedux/userActions";

//import tools
//import scss
import "./userList.scss";

function UserList() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const userList = state.user.userList;
  const roles = state.user.roles;
  const roleList = roles.map((item) => item.name);

  React.useEffect(() => {
    dispatch(retreiveUserListAction());
    // eslint-disable-next-line
  }, []);

  const goToEdit = ({ id, userData }) => {
    navigate("/user/update/" + id, {
      state: {
        userData: userData,
        roles: roleList,
      },
    });
  };

  const handleChangeUserDisableStatus = (item) => {
    const updatedUserData = {
      username: item.username,
      disabled: !item.disabled,
    };

    const confrmUserDisableStatus = async () => {
      await dispatch(
        createUpdateUserAction({
          actionMode: "edit",
          formData: updatedUserData,
        })
      );
      await dispatch(retreiveUserListAction());
    };

    dispatch(
      modalConfirm({
        func: () => confrmUserDisableStatus(),
        title: item.disabled ? t("Enable User") : t("Disable User"),
        info: item.disabled
          ? t("Are you sure you want to enable this user?")
          : t("Are you sure you want to disable this user?"),
        buttonCancel: t("components.modals.modalConfirmRetire.button_cancel"),
        buttonConfirm: t("components.modals.modalConfirmRetire.button_confirm"),
      })
    );

    return;
  };
  const goToNewUsers = () => {
    navigate("/user/new", {
      state: {
        roles: roleList,
      },
    });
    return;
  };

  const columns = [
    {
      field: "username",
      headerName: t("Username"),
      flex: 1.7,
      valueGetter: (params) => {
        return `${params.row.username}`;
      },
    },
    {
      field: "email",
      headerName: t("Email"),
      flex: 1.7,
      valueGetter: (params) => {
        return `${params.row.email ? params.row.email : "-"}`;
      },
    },
    {
      field: "roles",
      headerName: t("Roles"),
      flex: 1.7,
      type: "string",
      valueGetter: (params) => {
        const roles = params.row.roles;
        const userRoles =
          roles && roles[0] ? roles.map((item) => item.name) : [];
        return `${userRoles.join(", ")}`;
      },
    },
    {
      field: "id",
      headerName: t("screens.machines_list.table_header_actions"),
      flex: 1.3,
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() =>
              goToEdit({ id: params.row.id, userData: params.row })
            }
            className="buttonEdit"
          >
            <Create size="small" />
          </IconButton>
          <Switch
            checked={!params.row.disabled}
            onChange={() => handleChangeUserDisableStatus(params.row)}
            inputProps={{ "aria-label": "controlled" }}
            color="primary"
          />
        </div>
      ),
    },
  ];

  return (
    <Grid
      container
      direction="column"
      className="listmachinesGrid"
      style={{ width: "100%" }}
    >
      <Grid item xs={6} className="title">
        <Typography variant="h5">{t("List of users")}</Typography>
      </Grid>
      <Grid item xs={12} className="table">
        <DataGrid
          columns={columns}
          rows={userList}
          pageSize={10}
          rowsPerPageOptions={[10]}
        />
      </Grid>
      <Grid item xs={12} className="button">
        <Button
          variant="contained"
          id="machine_page-new_machine"
          color="inherit"
          startIcon={<Add />}
          size="small"
          onClick={goToNewUsers}
        >
          {t("Add new user")}
        </Button>
      </Grid>
    </Grid>
  );
}

export default UserList;
