import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@mui/icons-material";

//Import Actions
import {
  authenticateUserWithPassword,
  modalPasswordLost,
} from "redux/userRedux/userActions";

//Import Styles
import "./login.scss";

//import version
import pkg from "../../package.json";

function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  let user = state.common.user;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    //console.log("USE EFFECT LOGIN !");
    //when user is logged in , go to root. it will then redirect the user to its home screen
    if (user.username) {
      navigate("/");
    }
  }, [user, navigate]);
  if (user.username) {
    return <></>;
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      dispatch(authenticateUserWithPassword({ username, password }));
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container className="loginContainer">
      <Grid container direction="column" className="loginGrid">
        <Grid item xs={6}>
          <Typography variant="h6" className="title">
            {t("screens.login.text_secondary_title")}
          </Typography>
          <Typography variant="h4" className="title">
            {t("screens.login.text_primary_title")}
          </Typography>
          <Grid item xs={10} className="login-form-fields">
            <TextField
              variant="standard"
              label={t("screens.login.text_label_username")}
              className="textfieldUsername"
              id="login_screen-username"
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setUsername(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <TextField
              variant="standard"
              label={t("screens.login.text_label_password")}
              className="textfieldPassword"
              id="login_screen-password"
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
              type={showPassword ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyDown}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={handleClickShowPassword}>
                      {showPassword ? (
                        <Visibility
                          sx={{
                            fontSize: "23px",
                            color: "white",
                            marginBottom: "25px",
                          }}
                        />
                      ) : (
                        <VisibilityOff
                          sx={{
                            fontSize: "23px",
                            color: "white",
                            marginBottom: "25px",
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid container direction="row" dense="true" spacing="1">
            <Grid item xs={6}>
              <Button
                color="inherit"
                variant="contained"
                className="buttonLogin"
                id="login_screen-submitlogin"
                onClick={() => {
                  dispatch(
                    authenticateUserWithPassword({ username, password })
                  );
                }}
              >
                {t("screens.login.button_login")}
              </Button>
              <Button
                color="inherit"
                variant="contained"
                className="buttonLostPassword"
                onClick={() => {
                  dispatch(modalPasswordLost({}));
                }}
              >
                {t("screens.login.button_lost_password")}
              </Button>
            </Grid>
            {/* <Grid item xs={6}> */}
            {/* <Button
                color="inherit"
                variant="contained"
                className="buttonLostPassword"
                onClick={() => {
                  dispatch(modalPasswordLost({}));
                }}
              >
                {t("screens.login.button_lost_password")}
              </Button> */}
            {/* </Grid> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" dense="true" spacing="1">
        <Grid item xs>
          <Typography
            variant="body2"
            align="left"
            className="collaborationText"
          >
            {`v${pkg.version}`}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography
            variant="body2"
            align="center"
            className="collaborationText"
          >
            {t("screens.login.text_collaboration")}
          </Typography>
        </Grid>
        <Grid item xs />
      </Grid>
    </Container>
  );
}

export default Login;
