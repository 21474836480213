import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

//import actions
import { changePatternData } from "redux/fieldRedux/fieldActions";
//import components
import StageControl from "./stagecontrol";
import ProgressGauge from "components/progressgauge";
import RoleCheck from "components/rolecheck";
//import tools
//import {getActiveTask} from 'utils/tools';
import "./patterncard.scss";

function PatternCard(props) {
  const { t } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();
  // const state = useSelector((state) => (state));

  const [collapsed, setCollapsed] = React.useState(true);
  const [stageData, setStageData] = React.useState({});
  const [dataChanged, setDataChanged] = React.useState(false);
  const [priority, setPriority] = React.useState(props.pattern.priority);
  const [progress, setProgress] = React.useState(0);
  const [patternErrors, setPatternErrors] = React.useState({
    priority: "",
  });
  const [okToSave, setOkToSave] = React.useState(true);

  // eslint-disable-next-line
  useEffect(() => {
    const getPatternProgress = () => {
      // If Drilling filter selected (only showing Drilling stage), show drilling progress percentage
      if (props.pattern.patternStages.length === 1) {
        let stage = props.pattern.patternStages[0];
        if (
          stage.name === "drilling" &&
          stage.stageDrillingData &&
          stage.stageDrillingData.drilling_total_progress
        ) {
          setProgress(stage.stageDrillingData.drilling_total_progress);
          return;
        }
      }

      //count total tasks
      let totalTasks = 0;
      let completedTasks = 0;
      for (let i = 0; i < props.pattern.patternStages.length; i++) {
        for (
          let j = 0;
          j < props.pattern.patternStages[i].toggleTasks.length;
          j++
        ) {
          totalTasks++;
          if (props.pattern.patternStages[i].toggleTasks[j].value === 1) {
            completedTasks++;
          }
        }
      }
      setProgress(Math.ceil((completedTasks / totalTasks) * 100));
    };

    setDataChanged(
      stageData.value === true || props.pattern.priority !== priority
    );
    getPatternProgress();
  }, [props.pattern, priority, stageData.value]);

  const handlePriorityChange = (e) => {
    let newErrors = {
      priority: "",
    };

    const p = isNaN(e.target.value) ? -1 : Number(e.target.value);
    if (p < 0 || !Number.isInteger(p)) {
      newErrors.priority = t("components.patterncard.error_priority_numerical");
      setOkToSave(false);
      return;
    } else {
      setPriority(p);
      setOkToSave(true);
    }
    setPatternErrors(newErrors);
  };

  const handleToggleClick = () => {
    if (!collapsed) {
      setStageData({});
    }
    setCollapsed(!collapsed);
  };

  const handlePatternUpdateSave = () => {
    const patternUpdateObject = {
      _id: props.pattern.id,
      priority: priority,
      stageID: stageData.stageID,
      toggleTasks: [
        {
          name: stageData.name,
          value: stageData.value ? 1 : 0,
        },
      ],
    };
    //reset
    setStageData({});
    dispatch(changePatternData(patternUpdateObject));
  };

  const handlePatternForceComplete = () => {
    const patternUpdateObject = {
      _id: props.pattern.id,
      forceSkipStage: true,
    };
    setStageData({});
    dispatch(changePatternData(patternUpdateObject));
  };

  const onStageChange = (data) => {
    setStageData(data);
  };

  //handle chevron
  let chevron = <KeyboardArrowDown id="field_page-enlarge_pattern_card" />;
  if (!collapsed)
    chevron = <KeyboardArrowUp id="field_page-minimize_pattern_card" />;
  //handle classes
  let mainClass = "patternCardMain " + (collapsed ? "collapsed" : "expanded");
  let mainContent = <></>;
  //create collapsed content
  if (collapsed) {
    mainContent = (
      <Grid container direction="row">
        <Grid item xs={6} className="collapsedStageData">
          {props.pattern.patternStages.map((x, i) => {
            return <StageControl stage={x} key={i} isCollapsed={collapsed} />;
          })}
        </Grid>
        <Grid item xs={4} className="collapsedPatternData">
          <Grid container direction="row" className="pitData">
            <Grid item xs={6}>
              <Typography
                variant="body2"
                style={{ textTransform: "uppercase" }}
              >
                {t("components.patterncard.text_pit")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                style={{ textTransform: "capitalize" }}
              >
                {props.pattern.pit}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" className="priorityData">
            <Grid item xs={6}>
              <Typography
                variant="body2"
                style={{ textTransform: "uppercase" }}
              >
                {t("components.patterncard.text_priority")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                style={{ textTransform: "uppercase", fontWeight: "bold" }}
                className={"priority_" + priority}
              >
                {priority}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <ProgressGauge percentage={progress} />
        </Grid>
      </Grid>
    );
  } else {
    mainContent = (
      <Grid container direction="column">
        <Grid item xs={12} className="expandedPatternData">
          <Grid container direction="row" className="pitData">
            <Grid item xs={3}>
              <Typography
                variant="body2"
                style={{ textTransform: "uppercase" }}
              >
                {t("components.patterncard.text_pit")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                style={{ textTransform: "capitalize" }}
              >
                {props.pattern.pit}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2" className="unsavedChanges">
                {dataChanged && t("screens.field.text_unsaved_changes")}
              </Typography>
            </Grid>
          </Grid>
          <RoleCheck requiredRoles={["supervisor", "engineer", "director"]}>
            <Grid container direction="row" className="priorityData">
              <Grid item xs={3}>
                <Typography
                  variant="body2"
                  style={{ textTransform: "uppercase" }}
                >
                  {t("components.patterncard.text_priority")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  name="priority"
                  defaultValue={priority}
                  helperText={patternErrors.priority}
                  className="textfieldPatternPriority"
                  onChange={handlePriorityChange}
                />
              </Grid>
            </Grid>
          </RoleCheck>
        </Grid>
        <Grid item xs={12} className="expandedStageData">
          {props.pattern.patternStages.map((x, i) => {
            return (
              <StageControl
                stage={x}
                key={i}
                activeTask={props.pattern.activeTask}
                isCollapsed={collapsed}
                patternID={props.pattern.id}
                onDataChanged={onStageChange}
              />
            );
          })}
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} className="expandedButton">
            {props.stage === "drilling" ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handlePatternForceComplete}
                // size="small"
                id="field_page-complete_pattern_in_drilling_state"
              >
                {t("screens.field.button_force_complete")}
              </Button>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12} className="expandedButton">
            <Button
              variant="contained"
              color="primary"
              onClick={handlePatternUpdateSave}
              size="small"
              disabled={!dataChanged || !okToSave}
              id="field_page-save_pattern_change"
            >
              {t("screens.field.button_save")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  //create expanded content
  return (
    <Card className={mainClass}>
      <CardHeader
        action={
          <IconButton aria-label="settings" onClick={handleToggleClick}>
            {chevron}
          </IconButton>
        }
        title={
          t("components.patterncard.text_pattern") + " " + props.pattern.name
        }
      />
      <CardContent>{mainContent}</CardContent>
    </Card>
  );
}

export default PatternCard;
