import React from "react";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import { Container } from "@mui/material";

//import components
import ListMachines from "components/machines/listmachines";
import NewMachine from "components/machines/newmachine";
import InfoMachine from "components/machines/infomachine";

//import scss
import "./machines.scss";

function Machines() {
  const location = useLocation();

  const matchInfo = matchPath(
    { path: "/machines/info/:id" },
    location.pathname
  );
  const matchNew = matchPath({ path: "/machines/new" }, location.pathname);
  const matchEdit = matchPath(
    { path: "/machines/edit/:id" },
    location.pathname
  );
  //show list by default
  let pageContent = <ListMachines />;
  if (matchNew) {
    pageContent = <NewMachine mode="new" />;
  }
  if (matchEdit) {
    pageContent = <NewMachine mode="edit" id={matchEdit.params.id} />;
  }
  if (matchInfo) {
    pageContent = <InfoMachine id={matchInfo.params.id} />;
  }

  return <Container className="machinesContainer">{pageContent}</Container>;
}

export default Machines;
