import { Fragment } from "react";
import "./layoutWrapper.scss";
// import { useSelector, useDispatch } from 'react-redux'
// import { useLocation, useParams } from "react-router-dom";
//import components
import Navbar from "components/navbar";
import LoadOverlay from "components/loadingOverlay";
import ModalMain from "components/modals/modalMain";
import ToastContainer from "components/toastContainer";

//import utils
import AppInit from "utils/appInit";

function Layout(props) {
  // // ----------Localization hooks & Router Hooks-------------
  // const [location, params] = [useLocation(), useParams()]

  // // ----------Props & context & ref ------------------------------

  return (
    <Fragment>
      {/* <div className="app-wrapper"> */}
      <AppInit />
      <Navbar />
      <ToastContainer />
      <LoadOverlay />
      <ModalMain />
      <main className="layoutWrapper__mainContainer">{props.children}</main>
      {/* </div> */}
    </Fragment>
  );
}

export default Layout;
