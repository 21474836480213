import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Add } from "@mui/icons-material";

//Import css
import "./modalNewPattern.scss";

//Import Actions
import { modalClose } from "redux/commonRedux/commonActions";
import { addPattern } from "redux/fieldRedux/fieldActions";

function ModalNewPattern() {
  const { t } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  let pits = state.field.pits;
  // let supervisors = state.field.supervisors
  let engineers = state.field.engineers;

  const [pattern, setPattern] = useState({
    pit: "",
    priority: "",
    name: "",
    supervisor: "",
    engineer: "",
    notes: "",
  });
  const [patternErrors, setPatternErrors] = useState({
    pit: "",
    priority: "",
    name: "",
    supervisor: "",
    engineer: "",
  });
  const [formValid, setFormValid] = useState(false);

  const handlePatternChange = (e) => {
    if (e.target.name === "priority") {
      setPattern({ ...pattern, [e.target.name]: parseInt(e.target.value) });
      return;
    }
    setPattern({ ...pattern, [e.target.name]: e.target.value });
  };

  const handleAutocompleteChange = (e, value) => {
    const id = e.target.id.split("-")[0]; //first element of the id should be the control name
    if (!value) value = "";
    setPattern({ ...pattern, [id]: value.name || value.username });
  };

  const handlePatternSave = () => {
    dispatch(addPattern(pattern));
  };

  const formValidation = () => {
    let isFormValid = true;
    let newErrors = {
      pit: "",
      priority: "",
      name: "",
      engineer: "",
    };
    if (pattern.pit === "") {
      newErrors.pit = t("components.modals.modalNewPattern.error_pit_required");
    }
    if (pattern.priority === "") {
      newErrors.priority = t(
        "components.modals.modalNewPattern.error_priority_required"
      );
    } else if (!Number.isInteger(pattern.priority)) {
      newErrors.priority = t(
        "components.modals.modalNewPattern.error_priority_numerical"
      );
    }
    if (pattern.name === "") {
      newErrors.name = t(
        "components.modals.modalNewPattern.error_name_required"
      );
    }
    if (pattern.engineer === "") {
      newErrors.engineer = t(
        "components.modals.modalNewPattern.error_engineer_required"
      );
    }

    setPatternErrors(newErrors);
    //check if there is any error
    Object.keys(newErrors).forEach(function (key) {
      if (newErrors[key] !== "") {
        isFormValid = false;
      }
    });
    setFormValid(isFormValid);
  };

  React.useEffect(() => {
    formValidation();
    // eslint-disable-next-line
  }, [pattern]);

  return (
    <Dialog
      open={true}
      className="modalNewPattern"
      onClose={() => dispatch(modalClose())}
    >
      <DialogTitle>
        <Add />{" "}
        <span>{t("components.modals.modalNewPattern.text_primary_title")}</span>
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          options={pits}
          id="pit"
          onChange={handleAutocompleteChange}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label={t("components.modals.modalNewPattern.text_pit")}
              fullWidth
              margin="normal"
              className="textfieldPitName"
              InputLabelProps={{ shrink: true }}
              helperText={patternErrors.pit}
            />
          )}
        />
        <TextField
          required
          label={t("components.modals.modalNewPattern.text_priority")}
          fullWidth
          margin="normal"
          name="priority"
          className="textfieldPatternPriority"
          id="new_pattern_form-pattern_priority"
          InputLabelProps={{ shrink: true }}
          onBlur={handlePatternChange}
          helperText={patternErrors.priority}
        />
        <TextField
          required
          label={t("components.modals.modalNewPattern.text_pattern_name")}
          fullWidth
          margin="normal"
          name="name"
          className="textfieldPatternName"
          id="new_pattern_form-pattern_name"
          InputLabelProps={{ shrink: true }}
          onBlur={handlePatternChange}
          helperText={patternErrors.name}
        />
        {/*<Autocomplete options={supervisors} id="supervisor" onChange={handleAutocompleteChange} getOptionLabel={(option) => option.fullName}
         renderInput={(params) =>
         <TextField {...params} required label={t('components.modals.modalNewPattern.text_supervisor')} fullWidth margin="normal"
         className="textfieldSupervisor" InputLabelProps={{shrink: true}}/>
         }/>*/}
        <Autocomplete
          options={engineers}
          id="engineer"
          onChange={handleAutocompleteChange}
          getOptionLabel={(option) =>
            option.fullname !== "" ? option.fullname : option.username
          }
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label={t("components.modals.modalNewPattern.text_engineer")}
              fullWidth
              margin="normal"
              className="textfieldEngineer"
              InputLabelProps={{ shrink: true }}
              helperText={patternErrors.engineer}
            />
          )}
        />
        <TextField
          label={t("components.modals.modalNewPattern.text_additional_info")}
          multiline
          rows={3}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          name="notes"
          onBlur={handlePatternChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="inherit"
          onClick={() => {
            dispatch(modalClose());
          }}
        >
          {t("components.modals.modalNewPattern.button_cancel")}
        </Button>
        <Button
          variant="contained"
          id="new_pattern_form-save_pattern"
          color="primary"
          disabled={!formValid}
          onClick={handlePatternSave}
        >
          {t("components.modals.modalNewPattern.button_save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalNewPattern;
