import React from "react";
import { connect } from "react-redux";
import { checkRoles } from "utils/tools";

function RoleCheck(props) {
  //console.log(props.user.roles,props.requiredRoles);
  //returns empty if user doesn't have proper role permission
  if (!checkRoles(props.user.roles, props.requiredRoles)) return <div></div>;
  return (
    //returns content if user has proper permissions
    { ...props.children }
  );
}

const mapStateToProps = (state) => ({
  user: state.common.user,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(RoleCheck);
