import React from "react";
import { Grid, Popover, Typography } from "@mui/material";
import { CheckCircle, Error, Warning } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
//import tools
import { getDateTime } from "utils/tools";
//import scss
import "./machinestate.scss";

function MachineState(props) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    if (props.machine.prestartCheck.length === 0) return;
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const foundProblems =
    (props.machine.prestartCheck.checklist &&
      props.machine.prestartCheck.checklist.filter(
        (x) => x.hazardType === "hazardA" || x.hazardType === "hazardB"
      )) ||
    [];
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const className = "machineStateGrid state_" + props.machine.state.toString();
  let stateIcon = <></>;
  switch (props.machine.state) {
    case 0:
      stateIcon = <CheckCircle small="size" />;
      break;
    case 1:
      stateIcon = <Warning small="size" />;
      break;
    case 2:
      stateIcon = (
        <Error small="size" onClick={handlePopoverOpen} aria-describedby={id} />
      );
      break;
    default:
      break;
  }
  return (
    <Grid container direction="row" className={className}>
      <Grid item xs={2}>
        {stateIcon}
      </Grid>
      <Grid item xs={10} style={{ justifyContent: "right" }}>
        <Typography variant="body2">
          {t("common.machinestates.state_" + props.machine.state)}
        </Typography>
      </Grid>
      <Popover
        open={open}
        anchorEl={anchorEl}
        id={id}
        onClose={handlePopoverClose}
        className="machineStatePopover"
        anchorOrigin={{ vertical: "center", horizontal: "right" }}
        transformOrigin={{ vertical: "center", horizontal: "left" }}
      >
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h6" className="title">
              {" "}
              {t("common.machinestates.state_" + props.machine.state)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">
              {getDateTime(new Date(props.machine.prestartCheck.updatedAt))}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">
              {props.machine.prestartCheck.reportedBy}
            </Typography>
          </Grid>
          {foundProblems.length > 0 &&
            foundProblems.map((x) => {
              return (
                <Grid item key={x.name}>
                  <Typography variant="body2">
                    {t("common.vehicleevents." + x.name)}
                  </Typography>
                </Grid>
              );
            })}
        </Grid>
      </Popover>
    </Grid>
  );
}

export default MachineState;
