import React from "react";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import { Container } from "@mui/material";

//import components
import UserList from "components/users/UserList";
import UserCreate from "components/users/UserCreate";
// import UserDetail from 'components/users/UserDetail';
//import scss
import "./users.scss";

function Users() {
  const location = useLocation();

  // const matchInfo = matchPath(location.pathname, {
  //     path: '/user/info/:id',
  //     exact: true
  // });

  const matchNew = matchPath({ path: "/user/new" }, location.pathname);
  const matchEdit = matchPath({ path: "/user/update/:id" }, location.pathname);

  //show list by default
  let pageContent = <UserList />;
  if (matchNew) {
    pageContent = <UserCreate mode="new" />;
  }
  if (matchEdit) {
    pageContent = <UserCreate mode="edit" id={matchEdit.params.id} />;
  }
  // if (matchInfo) {
  //     pageContent = <UserDetail id={matchInfo.params.id} />
  // }

  return <Container className="usersContainer">{pageContent}</Container>;
}

export default Users;
