import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Grid, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
//import components
import RoleCheck from "components/rolecheck";
import StageDrilling from "components/patterns/stagedrilling";

//import actions
import { modalSurveyImport } from "redux/fieldRedux/fieldActions";

//import utils
import { GetLoggedInUser } from "api/auth";
import { checkRoles } from "utils/tools";

import "./stagecontrol.scss";

function StageControl(props) {
  const { t } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();
  // const state = useSelector((state) => (state));

  const [originalToggles, setOriginalToggles] = useState(
    props.stage.toggleTasks.map((x) => {
      return x.value === 1;
    })
  );
  const [toggles, setToggles] = useState(originalToggles);
  let stageStateClass = `state_${props.stage.state}`;
  const user = GetLoggedInUser();
  const patternID = props.patternID;

  useEffect(() => {
    setOriginalToggles(
      props.stage.toggleTasks.map((x) => {
        return x.value === 1;
      })
    );
  }, [props.stage.toggleTasks]);

  const handleToggleChange = (index, value) => {
    const updatedToggles = [...toggles];
    updatedToggles[index] = value;
    setToggles(updatedToggles);
    props.onDataChanged({
      stageID: props.stage.id,
      name: props.stage.toggleTasks[index].name,
      value: value,
    });
  };

  let mainGridContent = <></>;
  ///expanded version
  //with special cases for :
  //-survey (to upload data)
  //-drilling (separate UI)
  if (!props.isCollapsed) {
    //console.log(props.stage)
    let stageTasksContent = <></>;
    switch (props.stage.name) {
      case "drilling":
        if (props.stage.stageDrillingData) {
          stageTasksContent = (
            <Grid container direction="row" className="drillingTaskContainer">
              <StageDrilling stage={props.stage} />
            </Grid>
          );
        }
        break;
      default:
        stageTasksContent = (
          <Grid container direction="row" className="toggleTaskContainer">
            {props.stage.toggleTasks.map((x, i) => {
              //by default, toggle is disabled if this is not the current task or the value has already been set to true
              //also add permission logic in the mix
              let isToggleDisabled =
                x.order !== props.activeTask ||
                originalToggles[i] === true ||
                !checkRoles(user.roles, props.stage.write);
              //the toggle button should be disabled if the previous value was false
              //isToggleDisabled = isToggleDisabled || (i>0 && !originalToggles[i-1]);
              //handle "fake" tasks which will have custom controls other than toggles
              if (x.name.startsWith("fake_")) {
                switch (x.name) {
                  case "fake_plan_uploaded":
                    return (
                      <React.Fragment key={x.order}>
                        <Grid item xs={4} className="buttonLabel">
                          <Typography variant="body2">
                            {t("common.tasks." + x.name)}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} className="buttonInput">
                          <Button
                            variant="contained"
                            id="field_page-upload_pattern_csv"
                            color="primary"
                            disabled={isToggleDisabled}
                            onClick={() =>
                              dispatch(
                                modalSurveyImport({ patternID: patternID })
                              )
                            }
                          >
                            {t("common.tasks.btn_upload")}
                          </Button>
                        </Grid>
                      </React.Fragment>
                    );
                  default:
                    return <></>;
                }
              }
              return (
                <React.Fragment key={x.order}>
                  <Grid item xs={4} className="toggleLabel">
                    <Typography variant="body2">
                      {t("common.tasks." + x.name)}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} className="toggleInput">
                    <Switch
                      checked={toggles[i]}
                      id={x.name}
                      disabled={isToggleDisabled}
                      onChange={(e) => handleToggleChange(i, e.target.checked)}
                      color="primary"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        );
        break;
    }
    // #5348 Hide Blasting temporarily - remove the 2 lines below to enable it again
    mainGridContent = <></>;
    if (props.stage.name === "blasting") return mainGridContent;

    //extended version
    mainGridContent = (
      <React.Fragment>
        <Grid item xs={3}>
          <Typography variant="body2" style={{ textTransform: "uppercase" }}>
            {t("common.stages.stage_" + props.stage.name)}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography
            variant="body2"
            style={{ textTransform: "capitalize" }}
            className={stageStateClass}
          >
            {t("common.states.state_" + props.stage.state)}
          </Typography>
        </Grid>
        <Grid item xs={12} className="expandedTaskList">
          {stageTasksContent}
        </Grid>
      </React.Fragment>
    );
  } else {
    // #5348 Hide Blasting temporarily - remove the 2 lines below to enable it again
    mainGridContent = <></>;
    if (props.stage.name === "blasting") return mainGridContent;

    //collapsed version
    mainGridContent = (
      <React.Fragment>
        <Grid item xs={6}>
          <Typography variant="body2" style={{ textTransform: "uppercase" }}>
            {t("common.stages.stage_" + props.stage.name)}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="body2"
            style={{ textTransform: "capitalize" }}
            className={stageStateClass}
          >
            {t("common.states.state_" + props.stage.state)}
          </Typography>
        </Grid>
      </React.Fragment>
    );
  }
  //end result
  return (
    <RoleCheck requiredRoles={props.stage.read}>
      <Grid container direction="row" className="stagecontrolGrid">
        {mainGridContent}
      </Grid>
    </RoleCheck>
  );
}

export default StageControl;
