import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Link, Typography } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";

import RoleCheck from "components/rolecheck";
import FormMachine from "components/machines/formmachine";

//import actions
import {
  addVehicle,
  fetchVehicle,
  fetchVehicleOptions,
  modifyVehicle,
  removeVehicle,
} from "redux/machinesRedux/machinesActions";
import { modalConfirm } from "redux/commonRedux/commonActions";

//import scss
import "./newmachine.scss";

function NewMachine(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  let vehicleOptions = state.machines.options;
  let vehicle = state.machines.displayedVehicle;

  const title =
    props.mode === "new"
      ? t("screens.machines_new.text_add_new_machine")
      : t("screens.machines_new.text_edit_machine");
  const [formValid, setFormValid] = React.useState(false);
  const goToMachineList = (e) => {
    if (e) e.preventDefault();
    navigate("/machines/list");
    return;
  };

  React.useEffect(() => {
    //retrieve options

    if (props.mode === "edit" && !vehicle.serial) {
      dispatch(fetchVehicle(props.id));
    }
    if (vehicleOptions.vehicleTypes.length === 0) {
      dispatch(fetchVehicleOptions());
    }
    // eslint-disable-next-line
  }, [vehicle.serial, formValid]);

  const onFormDataChanged = (formValid) => {
    setFormValid(formValid);
  };

  const handleVehicleSave = async () => {
    if (props.mode === "new") {
      try {
        await dispatch(addVehicle(vehicle)).unwrap();
        navigate("/machines/list");
      } catch (error) {
        console.log(error);
      }
    }
    if (props.mode === "edit") {
      try {
        await dispatch(modifyVehicle(vehicle)).unwrap();
        navigate("/machines/list");
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleVehicleRetire = async () => {
    try {
      await dispatch(
        modalConfirm({
          func: () => dispatch(removeVehicle(vehicle.id)),
          title: t("components.modals.modalConfirmRetire.text_primary_title"),
          info: t("components.modals.modalConfirmRetire.text_primary_info"),
          buttonCancel: t("components.modals.modalConfirmRetire.button_cancel"),
          buttonConfirm: t(
            "components.modals.modalConfirmRetire.button_confirm"
          ),
        })
      ).unwrap();
      navigate("/machines/list");
    } catch (error) {
      console.log(error);
    }
  };

  //this makes sure the form is only visible if there is a proper data
  if (props.mode === "edit" && !vehicle.serial) {
    return <></>;
  }

  return (
    <Grid container direction="column" className="newmachineGrid">
      <Grid item xs={12} className="linkBack">
        <Typography variant="body2">
          <Link href="#" onClick={goToMachineList}>
            <ChevronLeft size="small" />{" "}
            <b>{t("screens.machines_new.text_back_to_machines")}</b>
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} className="title">
        <Typography variant="body2">{title}</Typography>
      </Grid>
      <Grid item xs={12} className="form">
        <Grid container direction="row">
          <Grid item xs={4}>
            <FormMachine
              mode={props.mode}
              vehicle={vehicle}
              vehicleOptions={vehicleOptions}
              dataChanged={onFormDataChanged}
            />
          </Grid>
          <Grid item xs={4}>
            -
          </Grid>
          <Grid item xs={4}>
            -
          </Grid>
          <Grid item xs={12} className="buttons">
            <Button
              variant="contained"
              color="primary"
              id="new_machine_form-save_machine"
              onClick={handleVehicleSave}
              disabled={!formValid}
            >
              {t("screens.machines_new.button_save")}
            </Button>
            {props.mode === "edit" && (
              <RoleCheck requiredRoles={["director"]}>
                <Button
                  variant="contained"
                  color="warning"
                  onClick={handleVehicleRetire}
                >
                  {t("screens.machines_new.button_retire")}
                </Button>
              </RoleCheck>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default NewMachine;
