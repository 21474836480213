import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Add } from "@mui/icons-material";

//Import css
import "./modalNewPit.scss";

//Import Actions
import { modalClose } from "redux/commonRedux/commonActions";
import { addPit } from "redux/fieldRedux/fieldActions";

//Import components
import UTMGridSelector from "components/patterns/utmgridselector";

function ModalNewPit(props) {
  const { t } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();

  const [pit, setPit] = useState({
    name: "",
    coordinates: [],
    utmGridNumber: "1",
    utmGridLetter: "A",
    z: "0",
  });

  const [pitErrors, setPitErrors] = useState({
    name: "",
  });

  const [formValid, setFormValid] = useState(false);

  const formValidation = () => {
    let isFormValid = true;
    let newErrors = {
      name: "",
    };
    if (pit.name === "") {
      newErrors.name = t("components.modals.modalNewPit.error_name_required");
    }

    setPitErrors(newErrors);
    //check if there is any error
    Object.keys(newErrors).forEach(function (key, index) {
      if (newErrors[key] !== "") {
        isFormValid = false;
      }
    });
    setFormValid(isFormValid);
  };

  React.useEffect(() => {
    formValidation();
    // eslint-disable-next-line
  }, [pit]);

  const handleCoordinateChange = (i, coord) => {
    let coords = pit.coordinates;
    coords[i] = coord;
    pit.coordinates = coords;
  };

  const handleGridNumberChange = (number) => {
    pit.utmGridNumber = number;
  };

  const handleGridLetterChange = (letter) => {
    pit.utmGridLetter = letter;
  };

  const handleNewPit = () => {
    let i, c, x, y;
    let locals = Array.from({ length: 4 });
    for (i = 0; i < 4; i++) {
      c = pit.coordinates[i];
      x = parseFloat(c.split(",")[0].trim());
      y = parseFloat(c.split(",")[1].trim());
      locals[i] = { lat: x, lon: y };
    }

    let utms = Array.from({ length: 4 });
    for (i = 4; i < 8; i++) {
      c = pit.coordinates[i];
      x = parseFloat(c.split(",")[0].trim());
      y = parseFloat(c.split(",")[1].trim());
      utms[i - 4] = { lat: x, lon: y };
    }

    const utmGrid = pit.utmGridNumber + pit.utmGridLetter;

    const createPitObject = {
      name: pit.name,
      localPoints: locals,
      utmPoints: utms,
      grid: utmGrid,
      z: parseFloat(pit.z),
    };
    dispatch(addPit(createPitObject));
  };

  return (
    <Dialog
      open={true}
      className="modalNewPit"
      onClose={() => dispatch(modalClose())}
      maxWidth={"lg"}
      fullWidth={true}
    >
      <DialogTitle>
        <Add />{" "}
        <span>{t("components.modals.modalNewPit.text_primary_title")}</span>
      </DialogTitle>
      <DialogContent>
        <TextField
          required
          label={t("components.modals.modalNewPit.text_pit_name")}
          className="textfieldPitName"
          id="new_pit_form-pit_name"
          fullWidth
          margin="normal"
          value={pit.name}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => setPit({ ...pit, name: e.target.value })}
          helperText={pitErrors.name}
        />
        <Typography variant="body2">
          {t("components.modals.modalNewPit.text_coordinates")}
        </Typography>
        <Grid container direction="row">
          <Grid item xs={6} className="localCoordinates">
            <List dense={true}>
              <ListItem>
                <TextField
                  required
                  fullWidth
                  margin="normal"
                  name="point1"
                  value={pit.coordinates[0]}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => handleCoordinateChange(0, e.target.value)}
                  label={
                    t("components.modals.modalNewPit.text_local_point") + " 1"
                  }
                />
              </ListItem>
              <ListItem>
                <TextField
                  required
                  fullWidth
                  margin="normal"
                  name="point2"
                  value={pit.coordinates[1]}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => handleCoordinateChange(1, e.target.value)}
                  label={
                    t("components.modals.modalNewPit.text_local_point") + " 2"
                  }
                />
              </ListItem>
              <ListItem>
                <TextField
                  required
                  fullWidth
                  margin="normal"
                  name="point3"
                  value={pit.coordinates[2]}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => handleCoordinateChange(2, e.target.value)}
                  label={
                    t("components.modals.modalNewPit.text_local_point") + " 3"
                  }
                />
              </ListItem>
              <ListItem>
                <TextField
                  required
                  fullWidth
                  margin="normal"
                  name="point4"
                  value={pit.coordinates[3]}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => handleCoordinateChange(3, e.target.value)}
                  label={
                    t("components.modals.modalNewPit.text_local_point") + " 4"
                  }
                />
              </ListItem>
              <ListItem>
                <TextField
                  fullWidth
                  margin="normal"
                  name="z"
                  placeholder={pit.z}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setPit({ ...pit, z: e.target.value })}
                  label={t("components.modals.modalNewPit.text_z_conversion")}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={6} className="utmCoordinates">
            <List dense={true} alignItems="flex-start">
              <ListItem>
                <TextField
                  required
                  fullWidth
                  margin="normal"
                  name="utmpoint1"
                  value={pit.coordinates[4]}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => handleCoordinateChange(4, e.target.value)}
                  label={
                    t("components.modals.modalNewPit.text_utm_point") + " 1"
                  }
                />
              </ListItem>
              <ListItem>
                <TextField
                  required
                  fullWidth
                  margin="normal"
                  name="utmpoint2"
                  value={pit.coordinates[5]}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => handleCoordinateChange(5, e.target.value)}
                  label={
                    t("components.modals.modalNewPit.text_utm_point") + " 2"
                  }
                />
              </ListItem>
              <ListItem>
                <TextField
                  required
                  fullWidth
                  margin="normal"
                  name="utmpoint3"
                  value={pit.coordinates[6]}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => handleCoordinateChange(6, e.target.value)}
                  label={
                    t("components.modals.modalNewPit.text_utm_point") + " 3"
                  }
                />
              </ListItem>
              <ListItem>
                <TextField
                  required
                  fullWidth
                  margin="normal"
                  name="utmpoint4"
                  value={pit.coordinates[7]}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => handleCoordinateChange(7, e.target.value)}
                  label={
                    t("components.modals.modalNewPit.text_utm_point") + " 4"
                  }
                />
              </ListItem>
              <ListItem id="gridselector" alignItems="flex-start">
                <UTMGridSelector
                  fullWidth
                  gridNumber={pit.gridNumber}
                  onNumberChange={handleGridNumberChange}
                  gridLetter={pit.gridLetter}
                  onLetterChange={handleGridLetterChange}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="inherit"
          onClick={() => {
            dispatch(modalClose());
          }}
        >
          {t("components.modals.modalNewPit.button_cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!formValid}
          onClick={handleNewPit}
          id="new_pit_form-save_pit_button"
        >
          {t("components.modals.modalNewPit.button_save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalNewPit;
