import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Grid, Link, Typography } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";

import UserForm from "components/users/UserForm";

//import actions
import { createUpdateUserAction } from "redux/userRedux/userActions";

//import scss
import "./userCreate.scss";

function UserCreate(props) {
  const navigate = useNavigate();
  let location = useLocation();

  const { t } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();

  const title = props.mode === "new" ? t("Add New User") : t("Update User ");

  const [formData, setFormData] = React.useState({});
  const [formValid, setFormValid] = React.useState(false);

  const goToMachineList = (e) => {
    if (e) e.preventDefault();
    navigate("/user/list");
  };

  const onFormDataChanged = (formValid) => {
    setFormValid(formValid);
  };

  const handleUpdateFormData = (formData) => {
    let newFormData = { ...formData };
    delete newFormData.passwordVerify;
    setFormData(newFormData);
  };

  const handleUserSave = async () => {
    const roles = formData.roles.map((item) => {
      return { name: item };
    });
    let newFormData = { formData: { ...formData, roles: roles } };
    delete newFormData.formData.id;
    if (props.mode === "new") {
      newFormData["actionMode"] = "new";
    } else if (props.mode === "edit") {
      newFormData["actionMode"] = "edit";
    }
    try {
      await dispatch(createUpdateUserAction(newFormData)).unwrap();
      navigate("/user");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid container direction="column" className="newUserGrid">
      <Grid item xs={12} className="linkBack">
        <Typography variant="body2">
          <Link href="#" onClick={goToMachineList}>
            <ChevronLeft size="small" /> <b>{t("Back to list of User")}</b>
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} className="title">
        <Typography variant="body2">{title}</Typography>
      </Grid>
      <Grid item xs={12} className="form">
        <Grid container direction="row">
          <Grid item xs={4}>
            <UserForm
              mode={props.mode}
              user={location.state ? location.state.userData : null}
              roles={location.state ? location.state.roles : []}
              dataChanged={onFormDataChanged}
              formDataChange={(formData) => handleUpdateFormData(formData)}
            />
          </Grid>
          <Grid item xs={4}>
            -
          </Grid>
          <Grid item xs={4}>
            -
          </Grid>
          <Grid item xs={12} className="buttons">
            <Button
              variant="contained"
              color="primary"
              id="save"
              onClick={handleUserSave}
              disabled={!formValid}
            >
              {t("save")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default UserCreate;
