import { createSlice, isAnyOf } from "@reduxjs/toolkit";

//Import actions
import { userLoggedOut } from "redux/userRedux/userActions";
import {
  addPattern,
  addPatternSuccess,
  addPit,
  addPitSuccess,
  cancelDrillPlanUpload,
  changePatternData,
  checkDrillPlan,
  fetchEngineers,
  fetchPatternByFilter,
  fetchPits,
  fetchSupervisors,
  fieldStageSelectionChange,
  modalSurveyImport,
  pitSelectionChanged,
  processDrillPlanSuccess,
  stageSelectionChanged,
  stateSelectionChanged,
  validateDrillPlan,
} from "redux/fieldRedux/fieldActions";

const initialState = {
  pits: [],
  stages: "",
  supervisors: [],
  fetchPitsLoading: false,
  engineers: [],
  patterns: [],
  filters: {
    stages: [],
    pits: [],
    states: [],
  },
  checkResults: {
    fileID: "",
    fileName: "",
    checkList: [],
  },
};

export const fieldSlice = createSlice({
  name: "fieldReducer",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // USER LOGGED OUT REDUCER
      .addCase(userLoggedOut, (state, action) => {
        //when user has logged out, empty all arrays
        state.pits = initialState.pits;
        state.supervisors = initialState.supervisors;
        state.engineers = initialState.engineers;
        state.patterns = initialState.patterns;
        state.filters.stages = initialState.filters.stages;
        state.filters.pits = initialState.filters.pits;
        state.filters.states = initialState.filters.states;
      })

      // // ADD / CREATE PIT REDUCER
      .addCase(addPit.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addPit.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addPit.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(addPitSuccess, (state, action) => {
        state.pits = [...state.pits, action.payload];
      })

      // // ADD PATTERN REDUCER
      .addCase(addPattern.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addPattern.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addPattern.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(addPatternSuccess, (state, action) => {
        state.patterns = [...state.patterns, action.payload]; // payload ==> patterns
      })

      // // FETCH PITS REDUCER
      .addCase(fetchPits.pending, (state, action) => {
        state.fetchPitsLoading = true;
      })
      .addCase(fetchPits.fulfilled, (state, action) => {
        state.pits = action.payload;
        state.fetchPitsLoading = false;
      })
      .addCase(fetchPits.rejected, (state, action) => {
        state.fetchPitsLoading = false;
      })

      .addCase(stageSelectionChanged, (state, action) => {
        state.filters.stages = action.payload;
      })
      .addCase(pitSelectionChanged, (state, action) => {
        state.filters.pits = action.payload;
      })
      .addCase(stateSelectionChanged, (state, action) => {
        state.filters.states = action.payload;
      })

      .addCase(fieldStageSelectionChange, (state, action) => {
        state.stages = action.payload;
      })

      // // ADD PATTERN REDUCER
      .addCase(fetchSupervisors.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchSupervisors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.supervisors = action.payload;
      })
      .addCase(fetchSupervisors.rejected, (state, action) => {
        state.isLoading = false;
      })

      // // FETCH ENGINEERS
      .addCase(fetchEngineers.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchEngineers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.engineers = action.payload;
      })
      .addCase(fetchEngineers.rejected, (state, action) => {
        state.isLoading = false;
      })

      // // //FETCH PATTERN BY FILTER REDUCER
      .addCase(fetchPatternByFilter.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchPatternByFilter.fulfilled, (state, action) => {
        state.patterns = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchPatternByFilter.rejected, (state, action) => {
        state.isLoading = false;
      })

      // // CHANGE PATTERN DATA REDUCER
      .addCase(changePatternData.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(changePatternData.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(changePatternData.rejected, (state, action) => {
        state.isLoading = false;
      })

      // // CHECK DRILL PLAN REDUCER
      .addCase(checkDrillPlan.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(checkDrillPlan.fulfilled, (state, action) => {
        state.isLoading = false;
        state.checkResults = action.payload;
      })
      .addCase(checkDrillPlan.rejected, (state, action) => {
        state.isLoading = false;
      })

      // // VALIDATE DRILL PLAN REDUCER
      .addCase(validateDrillPlan.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(validateDrillPlan.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(validateDrillPlan.rejected, (state, action) => {
        state.isLoading = false;
      })

      // // CANCEL DRILL PLAN UPLOAD REDUCER
      .addCase(cancelDrillPlanUpload.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(cancelDrillPlanUpload.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(cancelDrillPlanUpload.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addMatcher(
        isAnyOf(modalSurveyImport, processDrillPlanSuccess),
        (state, action) => {
          state.checkResults = initialState.checkResults;
        }
      );
  },
});
