import axios from "axios";
import { apiBaseURL } from "./baseURLconfig";
import { GetAuthToken } from "api/auth";
import { GetUserSettings } from "api/local";
import pkg from "../../../package.json";

/**
 * Interceptors are a feature that allows an application to intercept requests or responses before they are handled by the .then() or the .catch().
 * There are 2 type of interceptor 1) interceptors.request   &&   2) interceptors.response
 * Both types of Axios interceptors accept two functions.
 * The first function of the request interceptor modifies the request if it’s a valid, successful request,
 * the second function handles when the request is invalid and throws an error.
 *
 */

const axiosConfig = () => {
  const instance = axios.create();
  instance.defaults.baseURL = apiBaseURL;
  instance.defaults.timeout = 30000;

  // interceptors Request------------------------------------
  instance.interceptors.request.use(
    async (config) => {
      let token = await GetAuthToken();
      let userSettings = await GetUserSettings();
      config.headers = {
        ...config.headers,
        "x-ms-version": pkg.version,
        "x-ms-client": "insight",
        "x-ms-lang": userSettings.lang,
      };
      if (!!token) {
        config.headers = {
          ...config.headers,
          Authorization: "bearer " + token,
        };
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  //validating the token expiration scenario --------------------------
  // interceptors Response------------------------------------
  instance.interceptors.response.use(
    (Response) => {
      return Response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return instance;
};

export default axiosConfig;
