import React from "react";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import L from "leaflet";
import {
  LayersControl,
  MapContainer,
  Marker,
  Polygon,
  TileLayer,
  Tooltip,
} from "react-leaflet";

//leaflet
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

//import scss
import "./patternmap.scss";
import { getPatternsAverage } from "../../utils/tools";

function PatternMap(props) {
  // // ----------redux store useDispatch & useSelector --------------------
  // const dispatch = useDispatch()
  const state = useSelector((state) => state);

  let patterns = state.shift.patterns;
  let vehicles = state.shift.vehicles;

  const [patternPolygons, setPatternPolygons] = React.useState([]);
  const [machineMarkers, setMachineMarkers] = React.useState([]);

  //required to be able to display the marker correctly
  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
  });
  L.Marker.prototype.options.icon = DefaultIcon;

  React.useEffect(() => {
    //create patterns polygons
    let pList = [];
    patterns.forEach((x, i) => {
      const patternBoundaries = x.boundaries.map((y) => {
        return [y.latitude, y.longitude];
      });
      let patternOptions = {
        color: "#fe8204",
      };
      pList.push(
        <Polygon
          positions={patternBoundaries}
          pathOptions={patternOptions}
          key={i}
        >
          <Tooltip>
            <Typography variant="body2">{x.name}</Typography>
          </Tooltip>
        </Polygon>
      );
    });
    //create machine markers
    let mList = [];
    vehicles.forEach((x, i) => {
      mList.push(
        <Marker position={[x.location.latitude, x.location.longitude]} key={i}>
          <Tooltip direction="left">
            <Typography variant="body2">{x.name}</Typography>
          </Tooltip>
        </Marker>
      );
    });
    setPatternPolygons(pList);
    setMachineMarkers(mList);
    // eslint-disable-next-line
  }, [patterns]);

  //available list of layers : https://leaflet-extras.github.io/leaflet-providers/preview/
  return (
    patterns.length > 0 && (
      <Grid item xs={12} className="shiftPatternMap">
        <MapContainer
          center={getPatternsAverage(patterns, [5.96079, -1.93003])}
          zoom={15}
          scrollWheelZoom={true}
        >
          <LayersControl position="topright">
            <LayersControl.BaseLayer name="OpenStreetMap Mapnik">
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer checked name="Esri.WorldImagery">
              <TileLayer
                attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              />
            </LayersControl.BaseLayer>
          </LayersControl>
          {patternPolygons.map((x) => {
            return x;
          })}
          {machineMarkers.map((x) => {
            return x;
          })}
        </MapContainer>
      </Grid>
    )
  );
}

export default PatternMap;
