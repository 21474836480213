import React from "react";
import { connect } from "react-redux";

//Import css
import "./modalMain.scss";

//Import other modals
import ModalError from "./modalError";
import ModalPasswordLost from "./modalPasswordLost";
import ModalNewPit from "./modalNewPit";
import ModalNewPattern from "./modalNewPattern";
import ModalSurveyImport from "./modalSurveyImport";
import ModalMaintenance from "./modalMaintenance";
import ModalArchives from "./modalArchives";
import ModalConfirm from "./modalConfirm";

const MODAL_COMPONENTS = {
  MODAL_ERROR: ModalError,
  MODAL_PASSWORDLOST: ModalPasswordLost,
  MODAL_NEW_PIT: ModalNewPit,
  MODAL_NEW_PATTERN: ModalNewPattern,
  MODAL_SURVEY_IMPORT: ModalSurveyImport,
  MODAL_MAINTENANCE: ModalMaintenance,
  MODAL_ARCHIVES: ModalArchives,
  MODAL_CONFIRM: ModalConfirm,
};

function ModalMain(props) {
  //console.log(props);
  if (!props.modal.type) {
    //No modal
    return <span />;
  } else {
    const SpecificModal = MODAL_COMPONENTS[props.modal.type];
    return <SpecificModal {...props} />;
  }
}

const mapStateToProps = (state) => ({
  modal: state.common.modal,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ModalMain);
