import { createSlice } from "@reduxjs/toolkit";

//Import actions
import { saveSettings } from "redux/profileRedux/profileActions";

const initialState = {};

export const profileSlice = createSlice({
  name: "profileReducer",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // // FETCH PITS REDUCER
      .addCase(saveSettings.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(saveSettings.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(saveSettings.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});
