import React from "react";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import {
  LayersControl,
  MapContainer,
  Polygon,
  TileLayer,
  Tooltip,
} from "react-leaflet";
//import tools
import { getPatternsAverage } from "utils/tools";
//import scss
import "./patternmap.scss";

function PatternMap(props) {
  // // ----------redux store useDispatch & useSelector --------------------
  // const dispatch = useDispatch()
  const state = useSelector((state) => state);
  let patterns = state.field.patterns;

  const [patternPolygons, setPatternPolygons] = React.useState([]);
  React.useEffect(() => {
    let pList = [];
    patterns.forEach((x) => {
      const patternBoundaries = x.boundaries.map((y) => {
        return [y.latitude, y.longitude];
      });
      let patternOptions = {
        color: "#fe8204",
      };
      pList.push(
        <Polygon
          positions={patternBoundaries}
          pathOptions={patternOptions}
          key={x.name}
        >
          <Tooltip>
            <Typography variant="body2">
              {x.pit} - {x.name}
            </Typography>
          </Tooltip>
        </Polygon>
      );
    });
    setPatternPolygons(pList);

    // eslint-disable-next-line
  }, [patterns]);

  //available list of layers : https://leaflet-extras.github.io/leaflet-providers/preview/
  return (
    patterns.length > 0 && (
      <Grid
        item
        xs={12}
        className="fieldPatternMap"
        id="field_page-pattern_map"
      >
        <MapContainer
          center={getPatternsAverage(patterns, [5.96079, -1.93003])}
          zoom={15}
          scrollWheelZoom={true}
        >
          <LayersControl position="topright">
            <LayersControl.BaseLayer name="OpenStreetMap Mapnik">
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer checked name="Esri.WorldImagery">
              <TileLayer
                attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              />
            </LayersControl.BaseLayer>
          </LayersControl>
          {patternPolygons.map((x) => {
            return x;
          })}
        </MapContainer>
      </Grid>
    )
  );
}

export default PatternMap;
