import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { Info } from "@mui/icons-material";
//import components
import MachineState from "components/machines/machinestate";
//import EventsMachine from 'components/machines/eventsmachine';
import NotesMachine from "components/machines/notesmachine";
import DataPopupMachine from "components/machines/datapopupmachine";

//Import css
import "./modalArchives.scss";

//Import Actions
import { modalClose } from "redux/commonRedux/commonActions";
import { fetchVehicleArchives } from "redux/machinesRedux/machinesActions";

//Import Actions
import { getDateTime } from "utils/tools";

function ModalArchives(props) {
  const { t } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  let archives = state.machines.archives;

  React.useEffect(() => {
    dispatch(fetchVehicleArchives(props.modal.vehicle.serial));
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      field: "state",
      headerName: t("components.modals.modalArchives.table_header_status"),
      flex: 1,
      renderCell: (params) => (
        <MachineState
          machine={{ state: params.row.state, prestartCheck: [] }}
        />
      ),
    },
    {
      field: "startedAt",
      headerName: t("components.modals.modalArchives. table_header_startedAt"),
      flex: 1,
      renderCell: (params) => (
        <DataPopupMachine
          data={getDateTime(
            new Date(
              params.row.endedAt > "2000-01-01T01:01:00Z"
                ? params.row.endedAt
                : params.row.startedAt > "2000-01-01T01:01:00Z"
                ? params.row.startedAt
                : params.row.createdAt
            )
          )}
          state={params.row.state}
          tag="date"
        />
      ),
    },
    {
      field: "eventType",
      headerName: t("components.modals.modalArchives.table_header_type"),
      flex: 1,
      renderCell: (params) => (
        <DataPopupMachine
          data={params.row.eventType}
          state={params.row.state}
          tag="type"
        />
      ),
    },
    {
      field: "reportedFaults",
      headerName: t("components.modals.modalArchives.table_header_details"),
      flex: 1.5,
      renderCell: (params) => (
        <DataPopupMachine
          data={params.row.reportedFaults}
          state={params.row.state}
          tag="faults"
        />
      ),
    },
    {
      field: "reportedBy",
      headerName: t("components.modals.modalArchives.table_header_user"),
      flex: 1.5,
      renderCell: (params) => (
        <DataPopupMachine data={params.row.reportedBy} tag="user" />
      ),
    },
    {
      field: "notes",
      headerName: t("components.modals.modalArchives.table_header_notes"),
      flex: 3,
      renderCell: (params) => <NotesMachine notes={params.row.notes} />,
    },
  ];
  // to support Older Mongo Document are using createdAt otherwise no need
  const sortedArray = [...archives]
    .sort(
      (a, b) =>
        (a.endedAt > "2000-01-01T01:01:00Z"
          ? a.endedAt
          : a.startedAt > "2000-01-01T01:01:00Z"
          ? a.strtedAt
          : a.createdAt) -
        (b.endedAt > "2000-01-01T01:01:00Z"
          ? b.endedAt
          : b.startedAt > "2000-01-01T01:01:00Z"
          ? b.strtedAt
          : b.createdAt)
    )
    .reverse();
  return (
    <Dialog
      open={true}
      className="modalArchives"
      onClose={() => dispatch(modalClose())}
      fullWidth={true}
      maxWidth={"xl"}
    >
      <DialogTitle>
        <Info />
        <span>
          {t("components.modals.modalArchives.text_primary_title")}{" "}
          {props.modal.vehicle.name}
        </span>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" className="tableContainer">
          <Grid item xs={12} className="table">
            <DataGrid
              columns={columns}
              rows={sortedArray}
              pageSize={10}
              rowsPerPageOptions={[10]}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            dispatch(modalClose());
          }}
        >
          {t("components.modals.modalArchives.button_close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalArchives;
