import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { DropzoneArea } from "react-mui-dropzone";
import { Backup, CheckCircle, Error, Warning } from "@mui/icons-material";

//Import css
import "./modalSurveyImport.scss";

//Import Actions
import { modalClose } from "redux/commonRedux/commonActions";
import {
  cancelDrillPlanUpload,
  checkDrillPlan,
  validateDrillPlan,
} from "redux/fieldRedux/fieldActions";

function ModalSurveyImport(props) {
  const { t } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  let surveyResults = state.field.checkResults;

  const acceptedFiles = [];
  const patternID = props.modal.patternID;

  const HandleFileDrop = (files) => {
    if (files.length > 0) {
      let f = files[0];
      var reader = new FileReader();
      reader.onload = (function () {
        return function (e) {
          dispatch(
            checkDrillPlan({
              fileName: f.name,
              fileContent: e.target.result,
              patternID: patternID,
            })
          );
        };
      })(f);
      reader.readAsText(f);
    }
  };

  const handleCancel = () => {
    dispatch(modalClose());
    dispatch(cancelDrillPlanUpload(surveyResults.fileID));
  };

  return (
    <Dialog
      open={true}
      className="modalSurveyImport"
      onClose={() => dispatch(modalClose())}
      maxWidth="lg"
      fullWidth={true}
    >
      <DialogTitle>
        <Backup />{" "}
        <span>
          {t("components.modals.modalSurveyImport.text_primary_title")}
        </span>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" className="instructionText">
          {t("components.modals.modalSurveyImport.text_secondary_title")}
        </Typography>
        <Grid container direction="column" className="mainGrid">
          <Grid item xs={12}>
            <DropzoneArea
              onChange={HandleFileDrop}
              className="dropZone"
              acceptedFiles={acceptedFiles}
              dropzoneText={t(
                "components.modals.modalSurveyImport.text_dropzone"
              )}
              filesLimit={1}
              showPreviewsInDropzone={false}
              showAlerts={false}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className="fileName">
          <Typography variant="body1">{surveyResults.fileName}</Typography>
        </Grid>
        <Grid container direction="column" className="resultsGrid">
          {surveyResults.checkList
            .filter((y) => y.type === "info")
            .map((x, i) => {
              return (
                <Grid item xs={12} className="resultsInfo" key={i}>
                  <CheckCircle />
                  <Typography variant="body2">
                    {x.message} {x.details}
                  </Typography>
                </Grid>
              );
            })}
          {surveyResults.checkList
            .filter((y) => y.type === "warning")
            .map((x, i) => {
              return (
                <Grid item xs={12} className="resultsWarning" key={i}>
                  <Warning />{" "}
                  <Typography variant="body2">
                    {x.message} {x.details}
                  </Typography>
                </Grid>
              );
            })}
          {surveyResults.checkList
            .filter((y) => y.type === "error")
            .map((x, i) => {
              return (
                <Grid item xs={12} className="resultsError" key={i}>
                  <Error />
                  <Typography variant="body2">
                    {x.message} {x.details}
                  </Typography>
                </Grid>
              );
            })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleCancel}>
          {t("components.modals.modalSurveyImport.button_cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          id="field_page-pattern_card-upload_csv"
          onClick={() => {
            dispatch(validateDrillPlan(surveyResults.fileID));
          }}
          disabled={
            surveyResults.checkList.filter((y) => y.type === "error").length > 0
          }
        >
          {t("components.modals.modalSurveyImport.button_upload")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalSurveyImport;
