import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";

//import actions
import { hideToast } from "redux/commonRedux/commonActions";

//import scss
import "./toastContainer.scss";

function ToastContainer() {
  const { t } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  let toast = state.common.toast;

  const opened = toast.hasOwnProperty("message");
  const handleClose = () => {
    dispatch(hideToast());
  };
  if (!opened) {
    return <></>;
  }
  //console.log(toast.error);
  return (
    <Snackbar
      autoHideDuration={toast.duration || 5000}
      onClose={handleClose}
      open={opened}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        variant="filled"
        onClose={handleClose}
        severity={toast.type || "success"}
      >
        <span>
          {t(toast.message)}&nbsp; - &nbsp;
          {toast.error && toast.error.toString()}
        </span>
      </Alert>
    </Snackbar>
  );
}

export default ToastContainer;
