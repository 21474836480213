// Api base url depend on environment
const handleGetApiBaseURL = () => {
  if (process.env.REACT_APP_BACKEND_API_ENDPOINT) {
    return process.env.REACT_APP_BACKEND_API_ENDPOINT;
  } else {
    return "/api";
  }
};

export const apiBaseURL = handleGetApiBaseURL();
