import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import DrillData from "components/shift/drilldata";
import { fetchShiftData } from "redux/shiftRedux/shiftActions";
//import scss
import "./listmachines.scss";

function ListMachines() {
  const { t } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();
  const reducerState = useSelector((state) => state);
  let vehicles = reducerState.shift.vehicles;
  let pitsFilter = reducerState.field.filters.pits;

  React.useEffect(() => {
    dispatch(fetchShiftData(pitsFilter));
    // eslint-disable-next-line
  }, [pitsFilter]);

  if (vehicles.length === 0) {
    return <Typography>{t("screens.shift.text_no_vehicles")}</Typography>;
  }

  return (
    <Grid container direction="column" className="listmachinesShiftGrid">
      {vehicles &&
        vehicles[0] &&
        vehicles.map((x, i) => {
          return <DrillData x={x} i={i} />;
        })}
    </Grid>
  );
}

export default ListMachines;
