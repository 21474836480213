import React from "react";
import { Grid, Popover, Typography } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";

//import scss
import "./notesmachine.scss";

function NotesMachine(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Grid container direction="row" className="notesRow">
      <Grid item xs={11}>
        <Typography variant="body2" className="notes">
          {props.notes}
        </Typography>
      </Grid>
      <Grid item xs={1} style={{ display: "flex", alignSelf: "center" }}>
        {props.notes.length > 1 && <MoreHoriz onClick={handlePopoverOpen} />}
      </Grid>
      <Popover
        open={open}
        anchorEl={anchorEl}
        id={id}
        onClose={handlePopoverClose}
        className="machineNotesPopover"
        anchorOrigin={{ vertical: "center", horizontal: "left" }}
        transformOrigin={{ vertical: "center", horizontal: "right" }}
      >
        <Grid container direction="column">
          <Grid item>
            <pre>
              <Typography variant="body2">{props.notes}</Typography>
            </pre>
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  );
}

export default NotesMachine;
