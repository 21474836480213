import React from "react";
import { useDispatch } from "react-redux";
import { Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
//Import Actions
import { fetchPits } from "redux/fieldRedux/fieldActions";

//Import Components
import PitSelector from "components/patterns/pitselector";
import ListMachines from "components/shift/listmachines";
import PatternMap from "components/shift/patternmap";
//Import Styles
import "./shift.scss";

function Shift() {
  const { t } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchPits());
    // eslint-disable-next-line
  }, []);

  return (
    <Container className="shiftContainer">
      <Grid container direction="column" className="shiftGrid">
        <Grid item xs={12}>
          <Typography variant="h5" className="shiftTitle">
            {t("screens.shift.primary_title")}
          </Typography>
          <Grid container className="pitList">
            <PitSelector />
          </Grid>
          <Grid
            container
            className="vehicleList"
            style={{ maxHeight: "100%", overflow: "hidden" }}
          >
            <Grid
              container
              direction="row"
              style={{ maxHeight: "100%", overflow: "hidden" }}
            >
              <Grid item xs={6} style={{ maxHeight: "100%", overflow: "auto" }}>
                <ListMachines />
              </Grid>
              <Grid item xs={6}>
                <PatternMap />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Shift;
