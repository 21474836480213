//this code is used to be ran at the app startup
import React, { useEffect } from "react";
import appStore from "redux/store";
import { setUserAuth } from "redux/userRedux/userActions";
import { GetLoggedInUser, IsUserAuthenticated } from "api/auth";
//import utils
//import {LocalizationContext} from '@utils/i18n';

const AppInit = () => {
  useEffect(() => {
    /*async function setLanguage(){
            //read language from async storage
            let settings =await GetUserSettings(); 
            //switch language
            setLocale(settings.lang);
        }*/
    function verifyAuth() {
      if (IsUserAuthenticated()) {
        let userInfo = GetLoggedInUser();
        appStore.dispatch(setUserAuth(userInfo));
      }
    }

    //setLanguage();
    verifyAuth();
  }, []);
  return <></>;
};

export default AppInit;
