const localStorage = window.localStorage;

function SetUserSettings(settings) {
  try {
    localStorage.setItem("settings", JSON.stringify(settings));
    return true;
  } catch (e) {
    return false;
  }
}

const savePitSelector = (newSelectedPits) => {
  try {
    localStorage.setItem("newSelectedPits", JSON.stringify(newSelectedPits));
  } catch (e) {
    console.log("save pit selector to local storage fail", e);
  }
};
const getPitSelector = () => {
  try {
    let selectedPist = localStorage.getItem("newSelectedPits");
    selectedPist = JSON.parse(selectedPist);
    return selectedPist;
  } catch (e) {
    console.log("get pit selector in local storage fail", e);
    return [];
  }
};
const cleanAllStateInFieldTab = () => {
  try {
    localStorage.removeItem("pitState");
    localStorage.removeItem("newSelectedPits");
  } catch (e) {
    console.log("save pit state to local storage fail", e);
  }
};
const savePitState = (pitState) => {
  try {
    localStorage.setItem("pitState", JSON.stringify(pitState));
  } catch (e) {
    console.log("save pit state to local storage fail", e);
  }
};
const getPitState = () => {
  try {
    let pitState = localStorage.getItem("pitState");
    pitState = JSON.parse(pitState);
    return pitState;
  } catch (e) {
    console.log("get pit state in local storage fail", e);
    return [];
  }
};

function GetUserSettings() {
  const defaultSettings = {
    lang: "en",
  };
  try {
    let settings = JSON.parse(localStorage.getItem("settings"));
    if (!settings || settings === null) {
      return defaultSettings;
    }
    return settings;
  } catch (e) {
    return defaultSettings;
  }
}

function ReadLogs() {
  try {
    let logs = JSON.parse(localStorage.getItem("logs"));
    if (!logs || logs === null) {
      return [];
    }
    return logs;
  } catch (e) {
    return [];
  }
}

function AddLog(logObject) {
  try {
    let logs = ReadLogs();
    logs.push({
      ts: new Date(),
      log: logObject,
    });
    //keep last 10 logs
    logs.splice(0, logs.length - 10);
    localStorage.setItem("logs", JSON.stringify(logs));
    return true;
  } catch (e) {
    return false;
  }
}

export {
  SetUserSettings,
  GetUserSettings,
  ReadLogs,
  AddLog,
  savePitSelector,
  getPitSelector,
  getPitState,
  savePitState,
  cleanAllStateInFieldTab,
};
