import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  fieldStageSelectionChange,
  stageSelectionChanged,
} from "redux/fieldRedux/fieldActions";

import "./stageselector.scss";

export const AvailablePatternStages = [
  {
    value: "preparation",
    label: "components.stageselector.text_stage_preparation",
    title: "components.stageselector.title_stage_preparation",
  },
  {
    value: "drilling",
    label: "components.stageselector.text_stage_drilling",
    title: "components.stageselector.title_stage_drilling",
  },
];

// #5348 Hide Blasting temporarily - uncomment the lines below to enable it again
//},{
//    value:"blasting",
//    label:"components.stageselector.text_stage_blasting",
//    title:"components.stageselector.title_stage_blasting",
//}];

function StageSelector(props) {
  const { t } = useTranslation();

  /** redux store useDispatch & useSelector ------------------------------ **/
  const dispatch = useDispatch();
  const fieldState = useSelector((state) => state.field);

  /** hooks useState ----------------------------------------------------- **/
  const [selectedStage, setSelectedState] = useState(
    fieldState.stages ? fieldState.stages : "preparation"
  );

  /** hooks useEffect----------------------------------------------------- **/
  React.useEffect(() => {
    dispatch(stageSelectionChanged([selectedStage]));
    // eslint-disable-next-line
  }, [selectedStage]);

  /** handler functions--------------------------------------------------- **/
  const handleStageSelectorClick = (stage) => {
    setSelectedState(stage);
    dispatch(fieldStageSelectionChange(stage));
  };

  return (
    <Grid container direction="column">
      <Grid container direction="row" className="stageSelectorGrid">
        {AvailablePatternStages.map((x, i) => {
          return (
            <Typography
              className={x.value === selectedStage ? "active" : ""}
              key={i}
              onClick={() => handleStageSelectorClick(x.value)}
            >
              {t(x.label)}
            </Typography>
          );
        })}
      </Grid>
      <Typography>
        {t(AvailablePatternStages.find((x) => x.value === selectedStage).title)}
      </Typography>
    </Grid>
  );
}

export default StageSelector;
