import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

//import actions
//import scss
import "./userForm.scss";

//import utils

function FormMachine(props) {
  const { t } = useTranslation();
  const roles = props.roles ? props.roles : [];

  // // ----------redux store useDispatch & useSelector --------------------
  const [user, setUser] = React.useState({
    id: props.mode === "new" ? "" : props.user.id,
    username: props.mode === "new" ? "" : props.user.username,
    fullname: props.mode === "new" ? "" : props.user.fullname,
    roles:
      props.mode === "new" ? [] : props.user.roles.map((item) => item.name),
    password: props.mode === "new" ? "" : "",
    passwordVerify: props.mode === "new" ? "" : "",
    email: props.mode === "new" ? "" : props.user.email ? props.user.email : "",
  });

  const [userErrors, setUserErrors] = React.useState({
    username: "",
    fullname: "",
    email: "",
    roles: "",
    password: "",
    passwordVerify: "",
  });
  const [isUpdatePassword, setIsUpdatePassword] = useState(
    props.mode === "new"
  );
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  React.useEffect(() => {
    props.formDataChange({ ...user });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    formValidation();
    // eslint-disable-next-line
  }, [user, isUpdatePassword]);

  const handleTextfieldChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
    props.formDataChange({ ...user, [e.target.name]: e.target.value });
    // dispatch(updateStateVehicle({ ...user, [e.target.name]: e.target.value }))
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const formValidation = () => {
    let formValid = true;
    let newErrors = {
      username: "",
      fullname: "",
      email: "",
      roles: "",
      password: "",
      passwordVerify: "",
    };
    if (user.username === "") {
      newErrors.username = t("Username is required");
    }
    if (user.fullname === "") {
      newErrors.fullname = t("Fullname is required");
    }

    const regExp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2}))$/;
    if (user.email !== "" && !user.email.match(regExp)) {
      newErrors.email = t("Please enter valid email.");
    }

    if (user.roles.length < 1) {
      newErrors.roles = t("User should have at least 1 role.");
    }

    if (isUpdatePassword && user.password === "") {
      newErrors.password = t("User password is required");
    }

    if (isUpdatePassword && user.passwordVerify === "") {
      newErrors.passwordVerify = t("User password is required");
    } else if (isUpdatePassword && user.passwordVerify !== user.password) {
      newErrors.passwordVerify = t("Password don't match");
    }

    setUserErrors(newErrors);
    //check if there is any error
    Object.keys(newErrors).forEach(function (key) {
      if (newErrors[key] !== "") {
        formValid = false;
      }
    });
    props.dataChanged(formValid);
  };

  // // DorpDown
  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(name, roles, theme) {
    return {
      fontWeight:
        roles.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleUserRolesChange = (event) => {
    const {
      target: { value },
    } = event;

    const newUser = {
      ...user,
      roles: typeof value === "string" ? value.split(",") : value, // On autofill we get a stringified value.
    };

    setUser(newUser);
    props.formDataChange(newUser);
  };

  if (props.mode === "new" || props.mode === "edit") {
    return (
      <Grid container direction="column" className="formMachineGrid">
        <Grid item>
          <TextField
            required
            variant="standard"
            label={t("Username")}
            fullWidth
            margin="normal"
            disabled={props.mode !== "new"}
            className="textfieldName"
            name="username"
            id="username"
            onChange={handleTextfieldChange}
            value={user.username}
            helperText={userErrors.username}
          />
        </Grid>
        <Grid item>
          <TextField
            required
            variant="standard"
            label={t("Full name")}
            fullWidth
            margin="normal"
            disabled={false}
            className="textfieldName"
            name="fullname"
            id="fullname"
            onChange={handleTextfieldChange}
            value={user.fullname}
            helperText={userErrors.fullname}
          />
        </Grid>
        <Grid item>
          <TextField
            label={t("Email")}
            variant="standard"
            fullWidth
            margin="normal"
            className="textfieldSerial"
            name="email"
            id="email"
            onChange={handleTextfieldChange}
            value={user.email}
            helperText={userErrors.email}
          />
        </Grid>

        <FormControl
          sx={{ m: 1, width: 500 }}
          style={{ marginTop: 10, marginLeft: 0 }}
        >
          <InputLabel
            id="demo-multiple-chip-label"
            style={{ marginTop: 5, marginLeft: 5 }}
          >
            Select User Roles
          </InputLabel>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={user.roles}
            onChange={handleUserRolesChange}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {roles.map((name) => (
              <MenuItem
                key={name}
                value={name}
                style={getStyles(name, roles, theme)}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
          <span
            style={{
              color: "red",
              fontFamily: "Flexo",
              marginTop: "3px",
              lineHeight: 1.66,
              fontSize: "18px",
            }}
          >
            {userErrors.roles}
          </span>
        </FormControl>

        {props.mode !== "new" && (
          <div style={{ marginTop: 30 }}>
            <input
              type="checkbox"
              id="isUpdatePassword"
              name="isUpdatePassword"
              checked={isUpdatePassword}
              onChange={() => setIsUpdatePassword(!isUpdatePassword)}
            />
            <label htmlFor="isUpdatePassword" style={{ fontSize: "12px" }}>
              {t("Update password")}
            </label>
          </div>
        )}

        {(props.mode === "new" || isUpdatePassword) && (
          <>
            <Grid item>
              <TextField
                required
                variant="standard"
                label={t("User password")}
                fullWidth
                margin="normal"
                type={showPassword ? "text" : "password"}
                className="textfieldSerial"
                name="password"
                id="user_password"
                onChange={handleTextfieldChange}
                value={user.password}
                helperText={userErrors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? (
                          <Visibility sx={{ fontSize: "23px" }} />
                        ) : (
                          <VisibilityOff sx={{ fontSize: "23px" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                required
                variant="standard"
                label={t("Confirm user password")}
                fullWidth
                margin="normal"
                type={showConfirmPassword ? "text" : "password"}
                className="textfieldSerial"
                name="passwordVerify"
                id="user_password_verify"
                onChange={handleTextfieldChange}
                value={user.passwordVerify}
                helperText={userErrors.passwordVerify}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={handleClickShowConfirmPassword}
                      >
                        {showConfirmPassword ? (
                          <Visibility sx={{ fontSize: "23px" }} />
                        ) : (
                          <VisibilityOff sx={{ fontSize: "23px" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
    );
  }
}

export default FormMachine;
