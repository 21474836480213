import React from "react";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";
//import { useTranslation } from "react-i18next";
import "./patterncard.scss";

//import components
import StageDetails from "components/board/stagedetails";

function PatternCard(props) {
  //const {t} = useTranslation();
  //console.log(props.pattern);
  return (
    <Card className="patternCardMain">
      <CardHeader title={`${props.pattern.pit} - ${props.pattern.name}`} />
      <CardContent>
        <Grid container direction="row" className="stagesGrid">
          {props.pattern.patternStages.map((x) => {
            return <StageDetails stage={x} key={x.id} />;
          })}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default PatternCard;
