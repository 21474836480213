import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
//import {LockOpen} from '@mui/icons-material';
import { pitSelectionChanged } from "redux/fieldRedux/fieldActions";

import "./pitselector.scss";
import { getPitSelector, savePitSelector } from "api/local";

//import stagecontrol from './stagecontrol';
function PitSelector() {
  const { t } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  let pits = state.field.pits;
  let pitsFilter = state.field.filters.pits;

  //using the filter so it keeps the values between containers
  const [selectedPits, SetSelectedPits] = React.useState(pitsFilter);

  const handlePitSelectorClick = (pitName) => {
    const foundPit = selectedPits.findIndex((x) => x === pitName);
    let newSelectedPits;
    if (foundPit === -1) {
      newSelectedPits = [...selectedPits, pitName];
    } else {
      newSelectedPits = selectedPits.filter((x) => x !== pitName);
    }
    SetSelectedPits(newSelectedPits);
    savePitSelector(newSelectedPits);
    dispatch(pitSelectionChanged(newSelectedPits));
  };

  React.useEffect(() => {
    const oldSelectedPits = getPitSelector();
    if (oldSelectedPits) {
      SetSelectedPits(oldSelectedPits);
      dispatch(pitSelectionChanged(oldSelectedPits));
    } else {
      dispatch(pitSelectionChanged(selectedPits));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="body1" className="pitselectorTitle">
          {t("components.pitselector.text_pit_selection")}
        </Typography>
      </Grid>
      <Grid container wrap="nowrap" direction="row" className="pitselectorGrid">
        {pits.map((x) => {
          return (
            <Grid item key={x.id}>
              <Button
                key={x.id}
                id={x.name}
                variant="contained"
                color={
                  selectedPits.indexOf(x.name) > -1 ? "primary" : "inherit"
                }
                onClick={() => handlePitSelectorClick(x.name)}
                size="small"
              >
                {x.name}
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}

export default PitSelector;
