import React from "react";
import { Grid, Typography } from "@mui/material";
//import scss
import "./levelindicator.scss";

function LevelIndicator(props) {
  let level = "";
  if (props.value > 10) level = "reallyLow";
  if (props.value > 30) level = "low";
  if (props.value > 50) level = "average";
  if (props.value > 80) level = "good";
  return (
    <Grid container direction="row" className={`level ${level}`}>
      <Typography variant="body2">{props.value} %</Typography>
    </Grid>
  );
}

export default LevelIndicator;
