import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { Add, Info } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
//import actions
import { openModalMaintenance } from "redux/machinesRedux/machinesActions";

//import tools
import { getDateTime } from "utils/tools";
//import scss
import "./tablemaintenance.scss";

function TableMaintenance(props) {
  const { t } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();
  // const state = useSelector((state) => (state));

  const columns = [
    {
      field: "updatedAt",
      headerName: t("components.table_maintenance.table_header_datetime"),
      flex: 1.5,
      valueGetter: (params) => `${getDateTime(new Date(params.row.updatedAt))}`,
    },
    {
      field: "eventType",
      headerName: t("components.table_maintenance.table_header_type"),
      flex: 1.5,
      renderCell: (params) => (
        <Tooltip title={t("common.vehicleevents." + params.row.eventType)}>
          <span className="table-cell-trucate">
            {t("common.vehicleevents." + params.row.eventType)}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "reportedBy",
      headerName: t("components.table_maintenance.table_header_mechanic"),
      flex: 2,
      renderCell: (params) => (
        <Tooltip title={params.row.reportedBy}>
          <span className="table-cell-trucate">{params.row.reportedBy}</span>
        </Tooltip>
      ),
    },
    {
      field: "details",
      headerName: t("components.table_maintenance.table_header_details"),
      flex: 1,
      renderCell: (params) => (
        <IconButton
          onClick={() =>
            dispatch(
              openModalMaintenance({
                vehicle: props.vehicle,
                maintenanceData: params.row,
                mode: "info",
              })
            )
          }
          className="buttonView"
        >
          <Info size="small" />
        </IconButton>
      ),
    },
  ];
  let tableContent = (
    <Typography variant="body1">
      {t("components.table_maintenance.no_history")}
    </Typography>
  );
  if (props.maintenanceEvents.length > 0) {
    tableContent = (
      <DataGrid columns={columns} rows={props.maintenanceEvents} pageSize={5} />
    );
  }
  //console.log(props.maintenanceEvents);
  return (
    <Grid item xs={12} className="tableMaintenance">
      <Grid container direction="column" className="tableContainer">
        <Grid item xs={12} className="mainTable">
          {tableContent}
        </Grid>
        <Grid item xs={12} className="buttons">
          <Button
            variant="contained"
            id="machine_info-new_maintenance"
            color="inherit"
            startIcon={<Add />}
            onClick={() =>
              dispatch(
                openModalMaintenance({
                  vehicle: props.vehicle,
                  mode: "new",
                  maintenanceData: {},
                })
              )
            }
          >
            {t("components.table_maintenance.button_new")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TableMaintenance;
