import React from "react";
import { Grid, Tooltip, Typography } from "@mui/material";
import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
//import tools
import { getDateTime } from "utils/tools";
//import scss
import "components/board/stagedetails.scss";

function StageDetails(props) {
  const { t } = useTranslation();
  // #5348 Hide Blasting temporarily - remove the line below to enable it again
  if (props.stage.name === "blasting") return <></>;

  //console.log(props.stage);
  return (
    <Grid item className="stageDetails">
      <Grid container direction="column">
        <Grid item className="stageTitle">
          <Typography variant="body2">
            {" "}
            {t("common.stages.stage_" + props.stage.name)}
          </Typography>
        </Grid>
        {props.stage.toggleTasks.map((x) => {
          const taskState = x.value === 1 ? "task completed" : "task new";
          const icon =
            x.value === 1 ? (
              <Tooltip title={x.user} placement="left">
                <CheckCircle />
              </Tooltip>
            ) : (
              <RadioButtonUnchecked />
            );
          const timestamp =
            x.value === 1
              ? getDateTime(new Date(x.updatedAt))
              : t("common.states.state_0");
          //TODO: add special case for drilling stage ?
          return (
            <Grid item className={taskState} key={x.order}>
              <Grid container direction="row">
                <Grid item xs={2} className="icon">
                  {icon}
                </Grid>
                <Grid item xs={10}>
                  <Grid container direction="column">
                    <Typography variant="body2" className="mainTask">
                      {x.order}: {t("common.tasks." + x.name)}
                    </Typography>
                    <Typography variant="body2" className="subTask">
                      {timestamp}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}

export default StageDetails;
