import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from "helper/config/axiosConfig";
import { addToast, loading, modalError } from "redux/commonRedux/commonActions";

let patternF = {};

// // MODAL
export const modalNewPit = createAction(
  "MODAL_NEW_PIT", // don't change type
  (model) => {
    return { payload: model };
  }
);

export const openModalNewPattern = createAction(
  "MODAL_NEW_PATTERN",
  (model) => {
    return { payload: model };
  }
);

export const modalSurveyImport = createAction(
  "MODAL_SURVEY_IMPORT",
  (model) => {
    return { payload: model };
  }
);

// Add or CREATE PIT
export const addPit = createAsyncThunk(
  "field/addPit",
  async (createObject, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().post("/pit", createObject);
      dispatch(addPitSuccess(response.data));
      dispatch(
        addToast({
          type: "success",
          message: "screens.field.toast_message_pit_saved_success",
        })
      );
      return response.data;
    } catch (err) {
      //add error toast
      dispatch(
        addToast({
          type: "error",
          message: "screens.field.toast_message_pit_saved_fail",
          error: err.response.data.details || err,
        })
      );
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const addPitSuccess = createAction("field/addPitSuccess", (model) => {
  return { payload: model }; // model ==> pit
});

// // ADD PATTERN
export const addPattern = createAsyncThunk(
  "field/addPattern",
  async (pattern, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().post("/pattern", pattern);
      dispatch(addPatternSuccess(response.data));
      dispatch(fetchPatternByFilter(patternF));
      dispatch(
        addToast({
          type: "success",
          message: "screens.field.toast_message_pattern_saved_success",
        })
      );
      return response.data;
    } catch (err) {
      dispatch(
        addToast({
          type: "error",
          message: "screens.field.toast_message_pattern_saved_fail",
          error: err.response.data.details,
        })
      );
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const addPatternSuccess = createAction(
  "field/addPatternSuccess",
  (model) => {
    return { payload: model };
  }
);

// // FETCH PITS
export const fetchPits = createAsyncThunk(
  "field/fetchPits",
  async (model, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().get("/pits");
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

// // FETCH SUPERVISORS
export const fetchSupervisors = createAsyncThunk(
  "field/fetchSupervisors",
  async (model, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().get("/user/getbyrole/supervisor"); // "/user/getbyrole/" + "supervisor"
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

// // FETCH ENGINEERS
export const fetchEngineers = createAsyncThunk(
  "field/fetchEngineers/",
  async (model, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().get("/user/getbyrole/engineer"); //"/user/getbyrole/" + "engineer"
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const modalNewPattern = () => {
  return (dispatch) => {
    dispatch(openModalNewPattern({}));
    dispatch(fetchPits());
    dispatch(fetchSupervisors());
    dispatch(fetchEngineers());
  };
};

// // STAGE SELECTION CHANGED ACTION
export const stageSelectionChanged = createAction(
  "field/stageSelectionChanged",
  (model) => {
    return { payload: model };
  }
);

// // STAGE SELECTION CHANGE
export const fieldStageSelectionChange = createAction(
  "field/fieldStageSelectionChange",
  (model) => {
    return { payload: model };
  }
);

// // STATE SELECTION CHANGED ACTION
export const pitSelectionChanged = createAction(
  "field/pitSelectionChanged",
  (model) => {
    return { payload: model };
  }
);

// // STATE SELECTION CHANGED ACTION
export const stateSelectionChanged = createAction(
  "field/stateSelectionChanged",
  (model) => {
    return { payload: model };
  }
);

// // //FETCH PATTERN BY FILTER ACTION
export const fetchPatternByFilter = createAsyncThunk(
  "field/fetchPatternByFilter",
  async (patternFilter, { rejectWithValue, dispatch }) => {
    try {
      //if all filters are empty, don't do anything
      //this is done to avoid making a backend call with all filters empty for no reason (this happens when the page loads for the first time)
      if (
        patternFilter.pits.length === 0 &&
        patternFilter.stages.length === 0 &&
        patternFilter.states.length === 0
      ) {
        return [];
      }
      const { pits, stages, states } = patternFilter;
      const response = await axiosConfig().post("/pattern/byfilter", {
        pits,
        stages,
        states,
      });
      patternF = patternFilter;
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

// // CHANGE PATTERN DATA
export const changePatternData = createAsyncThunk(
  "field/changePatternData",
  async (updateObject, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().put("/pattern", updateObject);
      dispatch(fetchPatternByFilter(patternF));
      dispatch(
        addToast({
          type: "success",
          message: "screens.field.toast_message_pattern_update_success",
        })
      );
      return response.data;
    } catch (err) {
      dispatch(
        addToast({
          type: "error",
          message: "screens.field.toast_message_pattern_update_fail",
          error: err.response.data.details || err,
        })
      );
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

// // CHECK DRILL PLAN
export const checkDrillPlan = createAsyncThunk(
  "field/checkDrillPlan",
  async (model, { rejectWithValue, dispatch }) => {
    try {
      const { fileName, fileContent, patternID } = model;
      const response = await axiosConfig().post("/survey/drillplan", {
        fileName,
        fileContent,
        patternID,
      });
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

// // VALIDATE DRILL PLAN
export const validateDrillPlan = createAsyncThunk(
  "field/validateDrillPlan",
  async (fileID, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().get("/survey/drillplan/" + fileID);
      dispatch(processDrillPlanSuccess());
      dispatch(fetchPatternByFilter(patternF));
      dispatch(
        addToast({
          type: "success",
          message: "screens.field.toast_message_pattern_update_success",
        })
      );
      return response.data;
    } catch (err) {
      dispatch(loading(false));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const processDrillPlanSuccess = createAction(
  "field/processDrillPlanSuccess",
  (model) => {
    return { payload: model };
  }
);

// // CANCEL DRILL PLAN UPLOAD
export const cancelDrillPlanUpload = createAsyncThunk(
  "field/cancelDrillPlanUpload",
  async (fileID, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().delete(
        "/survey/drillplan/" + fileID
      );
      return response.data;
    } catch (err) {
      return rejectWithValue([], { data: err.response.data });
    }
  }
);
