import React from "react";
import { Grid, MenuItem, Select, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import "./utmgridselector.scss";

UTMGridSelector.propTypes = {
  gridNumber: PropTypes.number.isRequired,
  gridLetter: PropTypes.string.isRequired,
  onNumberChange: PropTypes.func.isRequired,
  onLetterChange: PropTypes.func.isRequired,
};

function UTMGridSelector(props) {
  const { t } = useTranslation();

  const grids = Array.from({ length: 60 }, (_, i) => i + 1);
  const letters = Array.from({ length: 22 }, (_, i) =>
    String.fromCharCode("C".charCodeAt(0) + i)
  );

  return (
    <Grid container direction="row" spacing={1} alignItems="flex-end">
      <Grid item xs={6}>
        <Typography
          variant="caption"
          className="gridSelectorTitle"
          InputLabelProps={{ shrink: true }}
        >
          {t("components.modals.modalNewPit.text_utm_grid")}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Select
          value={props.gridNumber}
          id="new_pit_form-grid_number"
          onChange={(e) => props.onNumberChange(e.target.value)}
          fullWidth
        >
          {grids.map((g, i) => (
            <MenuItem value={g} id={g} key={i}>
              {g}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={3}>
        <Select
          value={props.gridLetter}
          id="new_pit_form-grid_letter"
          onChange={(e) => props.onLetterChange(e.target.value)}
          fullWidth
        >
          {letters.map((l, i) => (
            <MenuItem value={l} id={l} key={i}>
              {l}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
}

export default UTMGridSelector;
