import React from "react";
import { useDispatch } from "react-redux";
import { Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
//Import Actions
import { fetchPits } from "redux/fieldRedux/fieldActions";

//Import Components
import PitSelector from "components/patterns/pitselector";
import PatternList from "components/board/patternlist";

//import scss
import "./board.scss";

function Board() {
  const { t } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();
  // const state = useSelector((state) => (state));

  React.useEffect(() => {
    dispatch(fetchPits());
    // eslint-disable-next-line
  }, []);

  return (
    <Container className="boardContainer">
      <Grid container direction="column" className="boardGrid">
        <Grid item xs={12}>
          <Typography variant="h5" className="patternStages">
            {t("screens.board.primary_title")}
          </Typography>
          <Grid container className="pitList">
            <PitSelector />
          </Grid>
          <Grid container className="patternList">
            <Grid container direction="column">
              <Grid item xs={12}>
                <PatternList />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Board;
