import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { SpinnerComponent } from "react-element-spinner";
import { useTranslation } from "react-i18next";

function LoadingOverlay() {
  const { t } = useTranslation();
  const loadingText = t("components.loadingoverlay.text_loading");

  // // *********loading functinality for api call depent on reducer*********
  const reducerState = useSelector((state) => state);
  let isLoading = reducerState.common.loading;
  let fetchPitsLoading = reducerState.field.fetchPitsLoading;

  const getIsLoadingValue = useMemo(() => {
    // // check isLoading in every reducer if in any reducer it is true then returen true elese (after checking every reducer) return false
    for (const reducer in reducerState) {
      //  for in loop to iterate object
      if (
        reducerState[reducer].isLoading === true ||
        reducerState[reducer].fetchPitsLoading === true
      ) {
        // checking (isLoading === true) condition in very reducer & if satisfied in any reducer then return true
        return true;
      }
    }
    // after checking (isLoading === true) condition in very reducer & not satisfied then return false
    return false;
  }, [reducerState]);

  return (
    <div style={{ zIndex: 99999999 }}>
      <SpinnerComponent
        loading={getIsLoadingValue || isLoading || fetchPitsLoading}
        position="global"
        message={loadingText}
      ></SpinnerComponent>
    </div>
  );
}

export default LoadingOverlay;
