import { configureStore } from "@reduxjs/toolkit";
import history from "utils/history";
//Import Reducer
import rootReducer from "redux/rootReducer";

const appStore = configureStore({
  reducer: rootReducer(history),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default appStore;
