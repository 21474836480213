import React from "react";
import { Grid, Slider, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

import { hoursToDuration } from "utils/tools";
//import components
import RoleCheck from "components/rolecheck";
import "./stagedrilling.scss";

const CustomSlider = withStyles({
  valueLabel: {
    fontSize: "0.7rem",
  },
})(Slider);

function StageDrilling(props) {
  const { t } = useTranslation();
  const [sliderValue, setSliderValue] = React.useState(
    props.stage.stageDrillingData
      ? props.stage.stageDrillingData.drilling_total_progress
      : 0
  );
  const sliderMarks = [
    { value: 0, label: "0%" },
    { value: 25, label: "25%" },
    { value: 50, label: "50%" },
    { value: 75, label: "75%" },
    { value: 100, label: "100%" },
  ];

  React.useEffect(() => {
    setSliderValue(
      props.stage.stageDrillingData
        ? props.stage.stageDrillingData.drilling_total_progress
        : 0
    );
    // eslint-disable-next-line
  }, [props.stage]);

  /*const handleSliderChange = () => {
          setSliderValue(props.stage.stageDrillingData.drilling_total_progress);
      }*/
  return (
    <RoleCheck requiredRoles={["engineer", "supervisor", "director"]}>
      <React.Fragment>
        <Grid item xs={12} className="progressSlider">
          <CustomSlider
            value={sliderValue}
            marks={sliderMarks}
            valueLabelDisplay="auto"
            aria-labelledby="discrete-slider-always"
          />
        </Grid>
        {/* Drilling Data */}
        <Grid item xs={4} className="drillingLabel">
          <Typography
            variant="body2"
            style={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            {t("common.tasks.drilling_drilled_holes")}
          </Typography>
        </Grid>
        <Grid item xs={2} className="drillingValue">
          <Typography variant="body2">
            {props.stage.stageDrillingData
              ? props.stage.stageDrillingData.drilling_drilled_holes
              : 0}
          </Typography>
        </Grid>
        <Grid item xs={4} className="drillingLabel">
          <Typography
            variant="body2"
            style={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            {t("common.tasks.drilling_holes_per_hour")}
          </Typography>
        </Grid>
        <Grid item xs={2} className="drillingValue">
          <Typography variant="body2">
            {props.stage.stageDrillingData
              ? props.stage.stageDrillingData.drilling_holes_per_hour
              : 0}
          </Typography>
        </Grid>
        <Grid item xs={4} className="drillingLabel">
          <Typography
            variant="body2"
            style={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            {t("common.tasks.drilling_remaining_holes")}
          </Typography>
        </Grid>
        <Grid item xs={2} className="drillingValue">
          <Typography variant="body2">
            {props.stage.stageDrillingData
              ? props.stage.stageDrillingData.drilling_remaining_holes
              : 0}
          </Typography>
        </Grid>
        {/*<Grid item xs={2} className="drillingValue">
                    <Typography variant='body2'>{props.stage.stageDrillingData?props.stage.stageDrillingData.drilling_total_holes-props.stage.stageDrillingData.drilling_drilled_holes:0}</Typography>
                </Grid>
                */}
        <Grid item xs={4} className="drillingLabel">
          <Typography
            variant="body2"
            style={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            {t("common.tasks.drilling_speed")}
          </Typography>
        </Grid>
        <Grid item xs={2} className="drillingValue">
          <Typography variant="body2">
            {props.stage.stageDrillingData
              ? props.stage.stageDrillingData.drilling_speed
              : 0}{" "}
            {t("common.units.meters_per_hour")}
          </Typography>
        </Grid>
        <Grid item xs={4} className="drillingLabel">
          <Typography
            variant="body2"
            style={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            {t("common.tasks.drilling_total_depth")}
          </Typography>
        </Grid>
        <Grid item xs={2} className="drillingValue">
          <Typography variant="body2">
            {props.stage.stageDrillingData
              ? props.stage.stageDrillingData.drilling_total_depth
              : 0}{" "}
            {t("common.units.meters")}
          </Typography>
        </Grid>
        <Grid item xs={4} className="drillingLabel">
          <Typography
            variant="body2"
            style={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            {t("common.tasks.drilling_remaining_time")}
          </Typography>
        </Grid>
        <Grid item xs={2} className="drillingValue">
          <Typography variant="body2">
            {hoursToDuration(
              props.stage.stageDrillingData
                ? props.stage.stageDrillingData.drilling_remaining_time
                : 0
            )}
          </Typography>
        </Grid>
        <Grid item xs={4} className="drillingLabel">
          <Typography
            variant="body2"
            style={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            {t("common.tasks.drilling_active_vehicles")}
          </Typography>
        </Grid>
        <Grid item xs={2} className="drillingValue">
          <Typography variant="body2">
            {props.stage.stageDrillingData
              ? props.stage.stageDrillingData.drilling_active_vehicles
              : 0}
          </Typography>
        </Grid>
      </React.Fragment>
    </RoleCheck>
  );
}

export default StageDrilling;
