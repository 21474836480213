import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from "helper/config/axiosConfig";

import { addToast, modalError } from "redux/commonRedux/commonActions";

export const saveSettings = createAsyncThunk(
  "user/profile",
  async (settings, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().post("/user/profile", settings);
      dispatch(
        addToast({
          type: "success",
          message: "screens.profile.toast_message_settings_saved_success",
        })
      );
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);
