import { createSlice } from "@reduxjs/toolkit";

//Import actions

const initialState = {
  patterns: [],
  /* filters:{
         pits:[]
     }*/
};

export const boardSlice = createSlice({
  name: "boardReducer",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase("test", (state, action) => {});
  },
});
