import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { RotateLeft } from "@mui/icons-material";

import { userPasswordLost } from "redux/userRedux/userActions";

//Import css
import "./modalPasswordLost.scss";

//Import Actions
import { modalClose } from "redux/commonRedux/commonActions";

function ModalPasswordLost() {
  const { t } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();
  // const state = useSelector((state) => (state));

  const [username, setUsername] = useState("");

  return (
    <Dialog
      open={true}
      className="modalPasswordLost"
      onClose={(event, reason) =>
        reason !== "backdropClick" && dispatch(modalClose())
      }
    >
      <DialogTitle>
        <RotateLeft />{" "}
        <span>
          {t("components.modals.modalPasswordReset.text_primary_title")}
        </span>
      </DialogTitle>
      <DialogContent>
        <TextField
          label={t("components.modals.modalPasswordReset.text_label_username")}
          className="textfieldUsername"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => setUsername(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            dispatch(modalClose());
          }}
        >
          {t("components.modals.modalPasswordReset.button_cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch(userPasswordLost(username));
          }}
        >
          {t("components.modals.modalPasswordReset.button_send")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalPasswordLost;
