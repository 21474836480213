import { createSlice } from "@reduxjs/toolkit";

//Import actions
import {
  authenticateUserWithPassword,
  createUpdateUserAction,
  fetchUserByUsername,
  retreiveUserListAction,
  userPasswordLost,
} from "redux/userRedux/userActions";

const initialState = {
  //user: {},
  roles: [],
  userList: [],
};

export const userSlice = createSlice({
  name: "userReducer",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // // // SIGNIN USER REDUCER
      .addCase(authenticateUserWithPassword.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(authenticateUserWithPassword.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(authenticateUserWithPassword.rejected, (state, action) => {
        state.isLoading = false;
      })

      // // // USER PASSWORD LOST  REDUCER
      .addCase(userPasswordLost.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(userPasswordLost.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(userPasswordLost.rejected, (state, action) => {
        state.isLoading = false;
      })

      // // //FETCH USER BY USERNAME REDUCER
      .addCase(fetchUserByUsername.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchUserByUsername.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchUserByUsername.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(createUpdateUserAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createUpdateUserAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createUpdateUserAction.rejected, (state, action) => {
        state.isLoading = false;
      })

      // // //FETCH USER LIST
      .addCase(retreiveUserListAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(retreiveUserListAction.fulfilled, (state, action) => {
        const { roles, users } = action.payload;
        state.isLoading = false;
        state.roles = roles;
        state.userList = users;
      })
      .addCase(retreiveUserListAction.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});
