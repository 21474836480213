import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

//import localapi
import { GetLoggedInUser } from "api/auth";
//import tools
import { checkRoles } from "utils/tools";

function ProtectedRoute({ element, requiredRoles, ...rest }) {
  const navigate = useNavigate();
  useEffect(() => {
    //read user from local storage
    let user = GetLoggedInUser();
    if (!user || !user.username || !checkRoles(user.roles, requiredRoles)) {
      navigate("/login");
    }
  }, [requiredRoles, navigate]);

  return <>{element}</>;
}

export default ProtectedRoute;
