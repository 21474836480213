import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from 'react-redux';
// import { Container, Typography } from '@mui/material';
// import { useTranslation } from "react-i18next";
// import Iframe from 'react-iframe';

// import { GetLoggedInUser } from 'api/auth';
// import { fetchUserByUsername } from 'redux/userRedux/userActions';
// import { isURL, isOwnURL } from 'utils/tools';

//Import Styles
// import './analytics.scss';

function Analytics() {
  const navigate = useNavigate();

  let pbiUrl = process.env.REACT_APP_HORIZONS_PBI_URL;

  useEffect(() => {
    handleRedirectPowerBI();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRedirectPowerBI = () => {
    window.open(pbiUrl, "_blank");
    navigate("/");
  };

  // const { t, } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  // const dispatch = useDispatch()
  // const state = useSelector((state) => (state));
  // let usersettings = state.common.usersettings

  // const loggedInUser = GetLoggedInUser();
  // const pbiURL = process.env.REACT_APP_POWERBI_EMBED_URL;
  // React.useEffect(() => {
  //     if (loggedInUser.username) {
  //         dispatch(fetchUserByUsername(loggedInUser.username));
  //     }
  //     // eslint-disable-next-line
  // }, []);
  // const getReportId = () => {
  //     if ((isURL(pbiURL) || isOwnURL(pbiURL)) && usersettings && usersettings.roles) {
  //         //this loops thru all roles and return the first report id found in the roles settings
  //         for (var i = 0; i < usersettings.roles.length; i++) {
  //             if (usersettings.roles[i].settings && usersettings.roles[i].settings.reportId)
  //                 return usersettings.roles[i].settings.reportId;
  //         }
  //     }
  //     return "0";
  // }
  // if (getReportId() === "0") {
  //     return (
  //         <Container className="analyticsContainer">
  //             <Typography variang="h5">{t('screens.analytics.text_no_report')}</Typography>
  //         </Container>
  //     )
  // }

  return <></>;
}

export default Analytics;
