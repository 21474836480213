import React from "react";
// import { useDispatch, useSelector } from 'react-redux';
import { Typography } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import "./progressgauge.scss";

//play around with pie chart https://nivo.rocks/pie/
function ProgressGauge(props) {
  return (
    <div className="gaugeMain">
      <ResponsivePie
        data={[
          { id: "c", color: "#7fbae7", value: props.percentage },
          {
            id: "r",
            value: 100 - props.percentage,
            color: "#6a7685",
          },
        ]}
        width={100}
        height={100}
        innerRadius={0.8}
        legends={false}
        radialLabel={false}
        enableSliceLabels={false}
        enableRadialLabels={false}
        isInteractive={false}
        sliceLabel="label"
        colors={{ datum: "data.color" }}
      />
      <div className="gaugeCenter">
        <Typography>{props.percentage}%</Typography>
      </div>
    </div>
  );
}

export default ProgressGauge;
