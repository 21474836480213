import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from "helper/config/axiosConfig";
import {
  addToast,
  loading,
  modalClose,
  modalError,
} from "redux/commonRedux/commonActions";

//local api function
import { UserLogin, UserLogout } from "api/auth";
import { cleanAllStateInFieldTab } from "api/local";

// // SIGNIN USER ACTIONS
export const authenticateUserWithPassword = createAsyncThunk(
  "user/authenticateUserWithPassword",
  async (model, { rejectWithValue, dispatch }) => {
    try {
      const { username, password } = model;
      const response = await axiosConfig().post("/user/auth", {
        username,
        password,
      });
      // this will clean all state of button in field tab
      cleanAllStateInFieldTab();
      //this will set the local storage then dispatch store update
      dispatch(setUserAuth(UserLogin(response.data.token)));
      //this will retrieve more info about the user (info we cannot get from the token)
      dispatch(fetchUserByUsername(username));
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const modalPasswordLost = createAction(
  "MODAL_PASSWORDLOST", // don't change type
  (model) => {
    return { payload: model };
  }
);

// // USER PASSWORD LOST ACTIONS
export const userPasswordLost = createAsyncThunk(
  "user/userPasswordLost",
  async (username, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().post("/user/password/lost", {
        username,
      });
      dispatch(userPasswordLostSuccess());
      addToast({
        type: "success",
        message: "components.modals.modalPasswordReset.text_toast_email_sent",
      });
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const userPasswordLostSuccess = createAction(
  "MODAL_PASSWORDLOST_SUCCESS", // don't change type
  (model) => {
    return { payload: model };
  }
);

// // SET USER AUTH
export const setUserAuth = (user) => {
  if (user) {
    return userLoggedIn(user);
  } else {
    return userLoggedOut(user);
  }
};

export const userLoggedIn = createAction("user/userLoggedIn", (model) => {
  return { payload: model };
});

export const userLoggedOut = createAction("user/userLoggedOut", (model) => {
  return { payload: model };
});

export const logoutUser = () => {
  return (dispatch) => {
    dispatch(loading(true));
    UserLogout();
    dispatch(setUserAuth(null));
    dispatch(loading(false));
  };
};

// // FETCH USER BY USERNAME ACTION
export const fetchUserByUsername = createAsyncThunk(
  "user/fetchUserByUsername/",
  async (username, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().get(
        "/user/getbyusername/" + username
      );
      dispatch(fetchUserByUsernameSuccess(response.data));
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const fetchUserByUsernameSuccess = createAction(
  "user/fetchUserByUsernameSuccess",
  (model) => {
    return { payload: model };
  }
);

export const createUpdateUserAction = createAsyncThunk(
  "user/createUpdateUserAction",
  async (model, { rejectWithValue, dispatch }) => {
    try {
      const { actionMode, formData } = model;
      let responseData;
      if (actionMode === "new") {
        const response = await axiosConfig().put("/user", { ...formData });
        responseData = response.data;
        dispatch(
          addToast({ type: "success", message: "User Added Successfully" })
        );
      } else if (actionMode === "edit") {
        const response = await axiosConfig().post("/user", { ...formData });
        responseData = response.data;
        dispatch(
          addToast({ type: "success", message: "User Updated Successfully" })
        );
        dispatch(modalClose());
      }
      return responseData;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const retreiveUserListAction = createAsyncThunk(
  "user/retreiveUserListAction",
  async (model, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().get("/user/web");
      const userData = response.data;
      return userData;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);
