import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { Add, ContentCopy, Create, Info } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

//import components
import MachineState from "components/machines/machinestate";
import LevelIndicator from "components/machines/levelindicator";
//import actions
import { fetchVehicles } from "redux/machinesRedux/machinesActions";
//import tools
import { getDateTime } from "utils/tools";
//import scss
import "./listmachines.scss";
//import vehicle services
import { getRandomString } from "helper/function/cryptoHelper";
import { generateSecret } from "redux/machinesRedux/machinesActions";
import { isLocal } from "App";

function ListMachines(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  let vehicles = state.machines.vehicles;

  React.useEffect(() => {
    dispatch(fetchVehicles());
    // eslint-disable-next-line
  }, []);

  const goToEdit = (id) => {
    navigate("/machines/edit/" + id);
    return;
  };
  const goToView = (id) => {
    navigate("/machines/info/" + id);
    return;
  };
  
  const generateNewSecret = async (id) => {
    if (window.confirm('Are you sure you would like to generate a new secret key?')) {
      const selectedVehicle = vehicles.find((v) => v.id === id);
      const secret_key = getRandomString();
      const updatedVehicle = {
        id: selectedVehicle.id,
        secret_key: secret_key,
        deviceName: selectedVehicle.deviceName,
        type: selectedVehicle.type,
        make: selectedVehicle.make,
        model: selectedVehicle.model,
        name: selectedVehicle.name,
        serial: selectedVehicle.serial,
        serviceFirst: selectedVehicle.serviceFirst,
        serviceInterval: selectedVehicle.serviceInterval,
        fuelType: selectedVehicle.fuelType,
        fuelCapacity: selectedVehicle.fuelCapacity,
        manufacturerURL: selectedVehicle.manufacturerURL,
        manualURL: selectedVehicle.manualURL,
        state: selectedVehicle.state,
        prestartcheckList: selectedVehicle.prestartcheckList,
        runningcheckList: selectedVehicle.runningcheckList,
      };
      try {
        await dispatch(generateSecret(updatedVehicle)).unwrap();
        navigator.clipboard.writeText(secret_key);
      } catch (error) {
        console.log(error);
      }
    };
  };
  const goToNewMachine = () => {
    navigate("/machines/new");
    return;
  };
  const columns = [
    {
      field: "state",
      headerName: t("screens.machines_list.table_header_status"),
      flex: 1.3,
      renderCell: (params) => <MachineState machine={params.row} />,
    },
    /* {
             field:'serial',
             headerName:t('screens.machines_list.table_header_serial'),
             width:150,
             valueGetter: (params) =>
                 `${params.getValue('serial').toUpperCase()}`,
         },*/
    /*{
            field:'type',
            headerName:t('screens.machines_list.table_header_type'),
            flex:1.5,
            valueGetter: (params) =>
                `${capitalizeString(params.row.type)}`,
        },
        {
            field:'make',
            headerName:t('screens.machines_list.table_header_make'),
            flex:1.3,
            valueGetter: (params) =>
                `${capitalizeString(params.row.make)}`,
        },
        {
            field:'model',
            headerName:t('screens.machines_list.table_header_model'),
            flex:1.5,
            valueGetter: (params) =>
                `${capitalizeString(params.row.model)}`,
        },*/
    {
      field: "name",
      headerName: t("screens.machines_list.table_header_name"),
      flex: 1.5,
    },
    {
      field: "prestartCheck.fuelLevel",
      headerName: t("screens.machines_list.table_header_fuel_level"),
      flex: 1,
      renderCell: (params) => (
        <LevelIndicator value={params.row.prestartCheck.fuelLevel} />
      ),
    },
    {
      field: "prestartCheck.oilLevel",
      headerName: t("screens.machines_list.table_header_oil_level"),
      flex: 1,
      renderCell: (params) => (
        <LevelIndicator value={params.row.prestartCheck.rockOilLevel} />
      ),
    },
    {
      field: "prestartCheck.waterLevel",
      headerName: t("screens.machines_list.table_header_water_level"),
      flex: 1.1,
      renderCell: (params) => (
        <LevelIndicator value={params.row.prestartCheck.waterLevel} />
      ),
    },
    {
      field: "prestartCheck.updatedAt",
      headerName: t("screens.machines_list.table_header_updated_at"),
      flex: 1.7,
      type: "string",
      valueGetter: (params) =>
        `${getDateTime(new Date(params.row.prestartCheck.updatedAt))}`,
    },
    {
      field: "lastService.updatedAt",
      headerName: t("screens.machines_list.table_header_last_service"),
      flex: 1.7,
      type: "string",
      valueGetter: (params) =>
        `${getDateTime(new Date(params.row.lastService.updatedAt))}`,
    },
    /*{
            field:'serviceInterval',
            headerName:t('screens.machines_list.table_header_next_service'),
            flex:1,
            valueGetter: (params) => (
                //TODO: Should be better way to calculate
                `${params.row.serviceInterval - (Math.abs(new Date() - new Date(params.row.lastService.updatedAt))/36e5)}`
            ),
        },*/
    {
      field: "id",
      headerName: t("screens.machines_list.table_header_actions"),
      flex: 1.3,
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() => goToEdit(params.row.id)}
            className="buttonEdit"
          >
            <Create size="small" />
          </IconButton>
          <IconButton
            onClick={() => goToView(params.row.id)}
            id="machine_list-view_machine"
            className="buttonView"
          >
            <Info size="small" />
          </IconButton>
          {isLocal ? (
          <IconButton
            onClick={() => generateNewSecret(params.row.id)}
            id="machine_list-copySecret"
            className="buttonView">
            <ContentCopy size="small" />
          </IconButton>
          ) : (<></>)}
        </div>
      ),
    },
  ];

  return (
    <Grid
      container
      direction="column"
      className="listmachinesGrid"
      style={{ width: "100%" }}
    >
      <Grid item xs={6} className="title">
        <Typography variant="h5">
          {t("screens.machines_list.text_list_machines")}
        </Typography>
      </Grid>
      <Grid item xs={12} className="table">
        <DataGrid
          columns={columns}
          rows={vehicles}
          pageSize={10}
          rowsPerPageOptions={[10]}
        />
      </Grid>
      <Grid item xs={12} className="button">
        <Button
          variant="contained"
          id="machine_page-new_machine"
          color="inherit"
          startIcon={<Add />}
          size="small"
          onClick={goToNewMachine}
        >
          {t("screens.machines.button_new_machine")}
        </Button>
      </Grid>
    </Grid>
  );
}

export default ListMachines;
