import React from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";

//Import css
import "./modalError.scss";

//Import Actions
import { modalErrorOk } from "redux/commonRedux/commonActions";

function ModalError(props) {
  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();

  let message = props.modal;
  if (message.response) {
    message = props.modal.response.data;
  }

  return (
    <Dialog
      open={true}
      className="modalError"
      onClose={(event, reason) =>
        reason !== "backdropClick" && dispatch(modalErrorOk())
      }
    >
      <DialogTitle>
        <ErrorOutline /> <span>An error has occured</span>
      </DialogTitle>
      <DialogContent>
        <div className="message">{message.message}</div>
        <div className="details">
          <pre>{JSON.stringify(message.details, undefined, 2)}</pre>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            dispatch(modalErrorOk());
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalError;
