import { createAction } from "@reduxjs/toolkit";
// import axiosConfig from "helper/config/axiosConfig";

// // LOADING
export const loading = createAction("common/loading", (model) => {
  return { payload: model };
});

// // TOAST --------------------------------------
export const addToast = createAction("common/addToast", (model) => {
  return { payload: model };
});

export const hideToast = createAction("common/hideToast", (model) => {
  return { payload: null };
});

// // MODAL---------------------------------
export const modalClick = (action, data) => {
  return {
    type: action,
    data,
  };
};

export const modalError = createAction("MODAL_ERROR", (model) => {
  return { payload: model };
});

export const modalErrorOk = createAction("common/modalErrorOk", (model) => {
  return { payload: model };
});

export const modalClose = createAction("common/modalClose", (model) => {
  return { payload: null };
});

export const modalConfirmNo = createAction("common/modalConfirmNo", (model) => {
  return { payload: model };
});

export const modalConfirm = createAction("MODAL_CONFIRM", (model) => {
  return { payload: model };
});

// ----------------------------------------------------------
// export const testAction = createAction(
//     'tests',
//     (model) => {
//         return { payload: model }
//     }
// )
// export const test = createAsyncThunk(
//     "test",
//     async (model, { rejectWithValue, dispatch }) => {
//         try {
//             const response = await axiosConfig().post("/model", model);
//             return response.data;
//         } catch (err) {
//             return rejectWithValue([], { data: err.response.data });
//         }
//     }
// );
// ----------------------------------------------------------
