import React from "react";
import { useDispatch } from "react-redux";
import { Button, Container, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Add } from "@mui/icons-material";
//Import Components
import StageSelector from "components/patterns/stageselector";
import PitSelector from "components/patterns/pitselector";
import StateSelector from "components/patterns/stateselector";
import PatternList from "components/patterns/patternlist";
import RoleCheck from "components/rolecheck";
import PatternMap from "components/patterns/patternmap";

//import scss
import "./field.scss";
//import actions
import {
  fetchPits,
  modalNewPattern,
  modalNewPit,
} from "redux/fieldRedux/fieldActions";

function Field(props) {
  const { t } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();
  // const state = useSelector((state) => (state));

  React.useEffect(() => {
    dispatch(fetchPits());
    // eslint-disable-next-line
  }, []);

  return (
    <Container className="fieldContainer">
      <Grid container direction="column" className="fieldGrid">
        <Grid item xs={12}>
          <Grid container className="patternStages">
            <StageSelector />
          </Grid>
          <Grid container className="pitList">
            <PitSelector />
          </Grid>
          <Grid className="patternStatuses">
            <StateSelector />
            <Grid container direction="row" className="buttonGrid">
              <RoleCheck requiredRoles={["engineer", "director"]}>
                <Button
                  variant="contained"
                  id="field_page-new_pit_button"
                  color="inherit"
                  startIcon={<Add />}
                  size="small"
                  onClick={() => {
                    dispatch(modalNewPit({}));
                  }}
                >
                  {t("screens.field.button_new_pit")}
                </Button>
              </RoleCheck>
              <RoleCheck requiredRoles={["engineer", "director"]}>
                <Button
                  variant="contained"
                  id="field_page-new_pattern_button"
                  color="inherit"
                  startIcon={<Add />}
                  size="small"
                  onClick={() => {
                    dispatch(modalNewPattern());
                  }}
                >
                  {t("screens.field.button_new_pattern")}
                </Button>
              </RoleCheck>
            </Grid>
          </Grid>
          <Grid
            container
            className="patternList"
            style={{ maxHeight: "100%", overflow: "hidden" }}
          >
            <Grid
              container
              direction="row"
              style={{ maxHeight: "100%", overflow: "hidden" }}
            >
              <Grid item xs={6} style={{ maxHeight: "100%", overflow: "auto" }}>
                <PatternList />
              </Grid>
              <Grid item xs={6} className="patternMap">
                <PatternMap />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Field;
