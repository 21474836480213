import React from "react";
import { Grid, Popover, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

//import scss
import "./datapopupmachine.scss";

/// TODO:
/**
 * There might be a problem with "faults", The fetchVehicleArchives api call that modalArchives uses,
 * gets the faults as timestamped singular events. And this is used to populate the "gridview" for history.
 * Now this creates an issue that we can't show all faults that are currently active under one popup. Maybe this is intended
 * and when maintanance guy fixes problem we clear them all and can show machine as operational.
 * But then why use array to store the faults in the first place. Or maybe the array for faults should keep increasing
 * when faults are added one after another, but it isn't.
 *
 * I Guess someone tried to show all faults using eventMachines, but it has the same issue with
 * incoming data from fetchVehicleArchives.
 *
 * Also this might be connected with issue 6124
 */

function DataPopupMachine(props) {
  const { t } = useTranslation();

  //Select Correct renders for text and Popup data by using tags
  function selectGridText(props) {
    if (props.tag === "faults") {
      return (
        <Typography
          variant="body2"
          className={`state_${props.state}`}
          onClick={handlePopoverOpen}
        >
          {" "}
          {t("common.vehicleevents." + props.data[0])}{" "}
        </Typography>
      );
    } else if (props.tag === "date") {
      return (
        <Typography
          variant="body2"
          className={`state_${props.state}`}
          onClick={handlePopoverOpen}
        >
          {" "}
          {props.data}{" "}
        </Typography>
      );
    } else if (props.tag === "user") {
      return (
        <Typography variant="body2" onClick={handlePopoverOpen}>
          {" "}
          {props.data}{" "}
        </Typography>
      );
    } else if (props.tag === "type") {
      return (
        <Typography
          variant="body2"
          className={`state_${props.state}`}
          onClick={handlePopoverOpen}
        >
          {" "}
          {t("common.vehicleevents." + props.data)}{" "}
        </Typography>
      );
    }
  }

  function selectPopupText(props) {
    if (props.tag === "faults") {
      return (
        <Grid item key={props.data.join("__")}>
          {props.data.map((data) => (
            <Typography key={data} variant="body2">
              {t("common.vehicleevents." + data)}
            </Typography>
          ))}
        </Grid>
      );
    } else if (props.tag === "date") {
      return (
        <Grid item key={props.data}>
          <Typography variant="body2">{props.data}</Typography>
        </Grid>
      );
    } else if (props.tag === "user") {
      return (
        <Grid item key={props.data}>
          <Typography variant="body2">{props.data}</Typography>
        </Grid>
      );
    } else if (props.tag === "type") {
      return (
        <Grid item key={props.data.id}>
          <Typography variant="body2">
            {t("common.vehicleevents." + props.data)}
          </Typography>
        </Grid>
      );
    }
  }

  //Popover config
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [clickPosX, setClickPosX] = React.useState(null);
  const [clickPosY, setClickPosY] = React.useState(null);

  //Open popup on click position
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setClickPosX(event.clientX);
    setClickPosY(event.clientY);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "data-popover" : undefined;

  return (
    <Grid container direction="row" className="dataRow">
      <Grid item xs={11}>
        {selectGridText(props)}
      </Grid>
      <Popover
        open={open}
        anchorEl={anchorEl}
        id={id}
        onClose={handlePopoverClose}
        className="dataPopupMachine"
        anchorPosition={{ top: clickPosY, left: clickPosX }}
        anchorOrigin={{ vertical: "center", horizontal: "left" }}
        anchorReference="anchorPosition"
      >
        <Grid container direction="column">
          <Grid item>{selectPopupText(props)}</Grid>
        </Grid>
      </Popover>
    </Grid>
  );
}

export default DataPopupMachine;
