import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
//import actions
import { fetchPatternByFilter } from "redux/fieldRedux/fieldActions";
//import components
import PatternCard from "components/patterns/patterncard";
import { GetLoggedInUser } from "api/auth";
import { checkRoles } from "utils/tools";

function PatternList(props) {
  const { t } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const stage = state?.field?.filters?.stages
    ? state.field.filters.stages[0]
    : "";
  let patterns = state.field.patterns;
  let patternFilter = state.field.filters;

  const loggedInUser = GetLoggedInUser();
  React.useEffect(() => {
    dispatch(fetchPatternByFilter(patternFilter));
    // eslint-disable-next-line
  }, [patternFilter]);

  if (patterns.length === 0) {
    return (
      <Typography>{t("components.patternlist.text_no_pattern")}</Typography>
    );
  }
  return (
    <Grid item xs={12}>
      {patterns
        .filter((x) => {
          //we only want patterns that have at least a stage that the logged in user can read or write
          ///this is done to avoid displaying a pattern card with no stage inside
          for (let i = 0; i < x.patternStages.length; i++) {
            if (
              checkRoles(loggedInUser.roles, x.patternStages[i].read) ||
              checkRoles(loggedInUser.roles, x.patternStages[i].write)
            )
              return true;
          }
          return false;
        })
        .map((x) => {
          return <PatternCard stage={stage} pattern={x} key={x.id} />;
        })}
    </Grid>
  );
}

export default PatternList;
