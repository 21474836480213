import React from "react";
import { useDispatch } from "react-redux";
import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { stateSelectionChanged } from "redux/fieldRedux/fieldActions";

import "./stateselector.scss";
import { getPitState, savePitState } from "api/local";

export const AvailablePatternStates = [
  {
    value: 1,
    label: "common.states.state_1",
    id: "field_page-in_progress_state_filter",
  },
  {
    value: 0,
    label: "common.states.state_0",
    id: "field_page-new_state_filter",
  },
  {
    value: 3,
    label: "common.states.state_3",
    id: "field_page-completed_state_filter",
  },
];

function StateSelector(props) {
  const { t } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();

  const [selectedState, setSelectedState] = React.useState(1);

  const handleStateSelectorClick = (state) => {
    savePitState(state);
    setSelectedState(state);
    dispatch(stateSelectionChanged([state]));
  };

  React.useEffect(() => {
    const oldPitsState = getPitState();
    if (oldPitsState) {
      dispatch(stateSelectionChanged([oldPitsState]));
      setSelectedState(oldPitsState);
    } else {
      dispatch(stateSelectionChanged([selectedState]));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container direction="column" className="stateselectorMain">
      <Typography variant="body1" className="stateselectorTitle">
        {t("components.stateselector.text_state_selection")}
      </Typography>
      <Grid container direction="row" className="stateselectorGrid">
        {AvailablePatternStates.map((x) => {
          return (
            <Button
              key={x.value}
              id={x.id}
              variant="contained"
              color={selectedState === x.value ? "primary" : "inherit"}
              onClick={() => handleStateSelectorClick(x.value)}
              size="small"
            >
              {t(x.label)}
            </Button>
          );
        })}
      </Grid>
    </Grid>
  );
}

export default StateSelector;
