import jwt_decode from "jwt-decode";

const localStorage = window.localStorage;

function IsUserAuthenticated() {
  try {
    let token = JSON.parse(localStorage.getItem("auth"));
    if (!token || token == null) {
      return false;
    }
    //Decode token
    let decodedToken = jwt_decode(token);
    //check lifetime
    if (new Date() > new Date(decodedToken.exp * 1000)) {
      //if expired, log out
      UserLogout();
      return false;
    }
    return true;
  } catch (e) {
    UserLogout();
    return false;
  }
}

function UserLogout() {
  try {
    localStorage.setItem("auth", JSON.stringify(null));
    //this will update the store
    return true;
  } catch (e) {
    return false;
  }
}

function GetLoggedInUser() {
  try {
    let token = JSON.parse(localStorage.getItem("auth"));
    //Decode token
    let decodedToken = jwt_decode(token);
    let user = {
      username: decodedToken.user,
      fullname: decodedToken.name || decodedToken.user,
      settings: {
        language: decodedToken.lang,
      },
      validity: decodedToken.exp,
      roles: decodedToken.roles,
      groups: decodedToken.groups,
    };
    //console.log(user);
    return user ? user : null;
  } catch (e) {
    return null;
  }
}

function UserLogin(token) {
  try {
    //set in torage
    localStorage.setItem("auth", JSON.stringify(token));
    return GetLoggedInUser();
  } catch (e) {
    return null;
  }
}

function GetAuthToken() {
  let token = JSON.parse(localStorage.getItem("auth"));
  if (!token || token === null) {
    return "";
  }
  return token;
}

export {
  IsUserAuthenticated,
  GetLoggedInUser,
  UserLogin,
  UserLogout,
  GetAuthToken,
};
